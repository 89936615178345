/* You can add global styles to this file, and also import other style files */

@import url("~assets/css/bootstrap.min.css");
@import url("~assets/css/themify-icons.css");
@import "~@angular/cdk/overlay-prebuilt.css";
/*@import url('https://use.fontawesome.com/releases/v5.0.6/js/fontawesome.js');*/

/*@import url('https://fonts.googleapis.com/css?family=Muli:300,400,600,700,800');*/

@import "~@angular/material/prebuilt-themes/indigo-pink.css";
/* @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400..700&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400..700&family=Raleway:wght@400..700&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400..900&display=swap'); */
/* @font-face {
  font-family: "Raleway";
  src: local("Raleway"), url(./assets/fonts/Raleway/Raleway-VariableFont_wght.ttf) format("truetype");
  font-weight: 400 900;
 /* font-stretch: 75% 125%; */
/* font-style: normal; */
/* src: url('https://fonts.googleapis.com/css2?family=Raleway:wght@200..900&display=swap') format("truetype"); */
/* } */
/* @font-face {
  font-family: 'Raleway';
  src: url('path/to/font/file/myvariablefont.woff2') format('woff2-variations');
  font-weight: 125 950;
  font-stretch: 75% 125%;
  font-style: normal;
 } */
/* @font-face {
  font-family: "Raleway";
  src: url(assets/fonts/GothamBook.eot);
  src: url(assets/fonts/GothamBook.eot) format("embedded-opentype"),
    url(assets/fonts/GothamBook.woff) format("woff"),
    url(assets/fonts/GothamBook.ttf) format("truetype"),
    url(assets/fonts/GothamBook.svg#svgFontName) format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Gotham-Medium";
  src: url(assets/fonts/GothamMedium.eot);
  src: url(assets/fonts/GothamMedium.eot) format("embedded-opentype"),
    url(assets/fonts/GothamMedium.woff) format("woff"),
    url(assets/fonts/GothamMedium.ttf) format("truetype"),
    url(assets/fonts/GothamMedium.svg#svgFontName) format("svg");
  font-display: swap;
} */
@font-face {
  font-family: "Gotham-Medium";
  src: url(assets/fonts/GothamMedium.eot);
  src: url(assets/fonts/GothamMedium.eot) format("embedded-opentype"),
    url(assets/fonts/GothamMedium.woff) format("woff"),
    url(assets/fonts/GothamMedium.ttf) format("truetype"),
    url(assets/fonts/GothamMedium.svg#svgFontName) format("svg");
  font-display: swap;
}
@font-face {
  font-family: "Gotham-Light";
  src: url(assets/fonts/gotham/Gotham-Light.eot);
  src: url(assets/fonts/gotham/Gotham-Light.eot) format("embedded-opentype"),
    url(assets/fonts/gotham/Gotham-Light.woff) format("woff"),
    url(assets/fonts//gotham/Gotham-Light.svg#svgFontName) format("svg");
  font-display: swap;
}

.form-control:focus {
  box-shadow: none;
}

:focus {
  outline: none;
}

* {
  font-family: "Montserrat", "Raleway", "Muli", sans-serif;
  /* font-size: .6875rem; */
  font-variant-numeric: lining-nums;
}

body {
  background-color: #f9f9f9;
  padding-top: 64px;
  /* background-image: url("../assets/images/header-bg.png");
    background-size: cover; */
}

.mat-cell,
.mat-header-cell,
.mat-footer-cell {
  color: #343434;
  font-size: 0.6875rem;
  line-height: 1.45;
  font-weight: 400;
}

.font-GMLbl {
  font-family: "Gotham-Medium", "Muli", sans-serif;
  color: rgba(100, 100, 100, 0.7);
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.8rem;
}

.text-red {
  color: #e34e38;
}

.text-bluish {
  color: #12afec;
}

.text-blue {
  color: #12afec !important;
}

.text-bold-blue {
  color: #12afec;
  font-size: 1.125rem !important;
}

.text-dark-gray {
  color: #737373 !important;
}

.progress-bar {
  height: 0.3rem;
}

.socialLinkMenu button.mat-menu-item {
  height: 30px;
  line-height: 30px;
}

.socialLinkMenu .mat-menu-content:not(:empty) {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 2px;
}

.socialLinkMenu .mat-menu-item:hover:not([disabled]) {
  opacity: 0.9 !important;
}

.facebook-bg,
.facebook-bg:hover {
  background: #3b5999 !important;
  color: #fff !important;
}

.twitter-bg,
.twitter-bg:hover {
  background: #55acee !important;
  color: #fff !important;
}

.linkedin-bg,
.linkedin-bg:hover {
  background: #0077b5 !important;
  color: #fff !important;
}

.gplus-bg,
.gplus-bg:hover {
  background: #dd4b39 !important;
  color: #fff !important;
}

.youtube-bg,
.youtube-bg:hover {
  background: #cd201f !important;
  color: #fff !important;
}

.hidden {
  display: none !important;
}

.mat-drawer.mat-drawer-side {
  /* z-index: 2; */
  position: fixed;
  overflow-y: auto;
}

.font-12 {
  font-size: 0.75rem;
}

.font-18 {
  font-size: 0.75rem;
  line-height: 16px;
  letter-spacing: 0.64px;
}

/*.......................margin class ....................*/

/*margin all side*/

.m-a-0 {
  margin: 0px !important;
}

.m-a-5 {
  margin: 5px !important;
}

.m-a-10 {
  margin: 10px !important;
}

.m-a-15 {
  margin: 15px !important;
}

.m-a-20 {
  margin: 20px !important;
}

.m-a-25 {
  margin: 25px !important;
}

.m-a-30 {
  margin: 30px !important;
}

.m-a-35 {
  margin: 35px !important;
}

.m-a-40 {
  margin: 40px !important;
}

.m-a-45 {
  margin: 45px !important;
}

.m-a-50 {
  margin: 50px !important;
}

/*margin top*/

.m-t-0 {
  margin-top: 0px !important;
}

.m-t-2 {
  margin-top: 2px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-t-7 {
  margin-top: 7px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-t-35 {
  margin-top: 35px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.m-t-45 {
  margin-top: 45px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

.m-t-55 {
  margin-top: 55px !important;
}

.m-t-60 {
  margin-top: 60px !important;
}

/*margin bottom*/

.m-b-0 {
  margin-bottom: 0px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-b-35 {
  margin-bottom: 35px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.m-b-45 {
  margin-bottom: 45px !important;
}

.m-b-50 {
  margin-bottom: 50px !important;
}

/*margin left*/

.m-l-0 {
  margin-left: 0px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-l-15 {
  margin-left: 15px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.m-l-25 {
  margin-left: 25px !important;
}

.m-l-30 {
  margin-left: 30px !important;
}

.m-l-35 {
  margin-left: 35px !important;
}

.m-l-40 {
  margin-left: 40px !important;
}

.m-l-45 {
  margin-left: 45px !important;
}

.m-l-50 {
  margin-left: 50px !important;
}

/*margin right*/

.m-r-0 {
  margin-right: 0px !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-r-15 {
  margin-right: 15px !important;
}

.m-r-20 {
  margin-right: 20px !important;
}

.m-r-25 {
  margin-right: 25px !important;
}

.m-r-30 {
  margin-right: 30px !important;
}

.m-r-35 {
  margin-right: 35px !important;
}

.m-r-40 {
  margin-right: 40px !important;
}

.m-r-45 {
  margin-right: 45px !important;
}

.m-r-50 {
  margin-right: 50px !important;
}

/*.......................padding class ....................*/

/*padding all side*/

.p-a-0 {
  padding: 0px !important;
}

.p-a-5 {
  padding: 5px !important;
}

.p-a-10 {
  padding: 10px !important;
}

.p-a-15 {
  padding: 15px !important;
}

.p-a-20 {
  padding: 20px !important;
}

.p-a-25 {
  padding: 25px !important;
}

.p-a-30 {
  padding: 30px !important;
}

.p-a-35 {
  padding: 35px !important;
}

.p-a-40 {
  padding: 40px !important;
}

.p-a-45 {
  padding: 45px !important;
}

.p-a-50 {
  padding: 50px !important;
}

/*padding top*/

.p-t-5 {
  padding-top: 5px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-t-25 {
  padding-top: 25px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.p-t-35 {
  padding-top: 35px !important;
}

.p-t-40 {
  padding-top: 40px !important;
}

.p-t-45 {
  padding-top: 45px !important;
}

.p-t-50 {
  padding-top: 50px !important;
}

/*padding bottom*/

.p-b-0 {
  padding-bottom: 0px !important;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.p-b-25 {
  padding-bottom: 25px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.p-b-35 {
  padding-bottom: 35px !important;
}

.p-b-40 {
  padding-bottom: 40px !important;
}

.p-b-45 {
  padding-bottom: 45px !important;
}

.p-b-50 {
  padding-bottom: 50px !important;
}

/*padding left*/

.p-l-0 {
  padding-left: 0px !important;
}

.p-l-5 {
  padding-left: 5px !important;
}

.p-l-10 {
  padding-left: 10px !important;
}

.p-l-15 {
  padding-left: 15px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.p-l-25 {
  padding-left: 25px !important;
}

.p-l-30 {
  padding-left: 30px !important;
}

.p-l-35 {
  padding-left: 35px !important;
}

.p-l-40 {
  padding-left: 40px !important;
}

.p-l-45 {
  padding-left: 45px !important;
}

.p-l-50 {
  padding-left: 50px !important;
}

/*padding right*/

.p-r-0 {
  padding-right: 0px !important;
}

.p-r-5 {
  padding-right: 5px !important;
}

.p-r-10 {
  padding-right: 10px !important;
}

.p-r-15 {
  padding-right: 15px !important;
}

.p-r-20 {
  padding-right: 20px !important;
}

.p-r-25 {
  padding-right: 25px !important;
}

.p-r-30 {
  padding-right: 30px !important;
}

.p-r-35 {
  padding-right: 35px !important;
}

.p-r-40 {
  padding-right: 40px !important;
}

.p-r-45 {
  padding-right: 45px !important;
}

.p-r-50 {
  padding-right: 50px !important;
}

mat-table.highlight > mat-row {
  border-left: 5px solid transparent;
  border-bottom: 1px solid #dedede;
}

mat-table.highlight > mat-row:hover {
  background-color: #f4fbfe;
  border-left: 5px solid #12afec;
}

.border-b-1 {
  border-bottom: 1px solid #e5e4e4;
}

.border-b-4 {
  border-bottom: 4px solid #12afec;
}

.border-b-blank {
  border-bottom: 4px solid transparent;
}

.danger-bg,
.mat-danger-bg {
  background-color: #e34e38 !important;
}

.success-bg,
.mat-success-bg {
  background-color: #7ed321 !important;
}

.warning-bg,
.mat-warning-bg {
  background-color: #fbae17 !important;
}

.border-right-1 {
  border-right: 1px solid #ababab;
}

.border-left-1 {
  border-left: 1px solid #ababab;
}

.text-danger {
  color: #e34e38 !important;
}

.text-success {
  color: #7ed321 !important;
}

.text-warning {
  color: #fbae17 !important;
}

.text-gray {
  color: rgba(100, 100, 100, 0.72);
  letter-spacing: 0.64px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 16px;
}

.map_layoutG {
  height: 100%;
  width: 100%;
}

.map_layoutG#map {
  border: rgb(187, 187, 187) 1px solid;
}

.leftMenu::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, #ccc),
    color-stop(0.72, #ccc),
    color-stop(0.86, #ccc)
  );
}

.leftMenu::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.leftMenu::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 0px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/* a{color:#a90f2d;} */

/* a:hover{color:#3f51b5; text-decoration:none;} */

a {
  text-decoration: underline;
}

.mat-tab-label-container {
  padding-right: 15px;
}

.AssignTruckToggle {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  /* z-index: 1; */
  /* overflow-x: scroll !important; */
}

.mat-form-field-empty.mat-form-field-label,
.mat-checkbox-layout {
  font-size: 0.75rem;
}

.mat-form-field {
  margin-left: 0 !important;
}

.mat-calendar-body-selected {
  background-color: #12afec !important;
  color: #fff;
}

.fontWeight600 {
  font-weight: 600;
}

.siteLoading {
  font-weight: 600;
  padding-top: 10px;
}

ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.mLft5 {
  margin-left: 5px !important;
}

.mBtm10 {
  margin-bottom: 10px;
}

.mTop10 {
  margin-top: 10px;
}

.mTop20 {
  margin-top: 20px;
}

.mTop15 {
  margin-top: 15px;
}

.mBtm15 {
  margin-bottom: 15px;
}

.mBtm25 {
  margin-bottom: 25px;
}

.pBtm0 {
  padding-bottom: 0 !important;
}

.pBtm10 {
  padding-bottom: 10px !important;
}

.pTop10 {
  padding-top: 10px !important;
}

.mBtm0 {
  margin-bottom: 0px !important;
  padding-bottom: 20px;

  /* margin-bottom: 20px; */
}

.pL0 {
  padding-left: 0px !important;
}

.pLR0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.header {
  height: 64px;
  /* height: 113px; */
  padding: 5px 35px 90px 50px;
  /* background-color: #f5ec48; */
  /* box-shadow: 0 3px 5px -1px rgba(0,0,0,.2),0 6px 10px 0 rgba(0,0,0,.14),0 1px 18px 0 rgba(0,0,0,.12); */
  position: fixed;
  z-index: 3;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  background-image: url(assets/img/header-bg.svg);
  background-size: auto 100%;
  background-position: 0px 0px;
}

.mat-drawer {
  /* background-color: #e34e38 !important; */
  background-color: #ffffff !important;
  color: rgba(255, 255, 255, 0.87);
}

.header .mat-icon {
  color: #12afec !important;
}

.rightUserSec {
  float: right;
  margin-right: 15px;
  display: flex;
  /* align-items: center; */
}

.colorOrg {
  background-color: #df4000;
}

.colorGrey {
  background-color: #3b3954;
}

.formWhiteBox {
  text-align: center;
  color: #444;
  /* padding: 3em; */
}

.logo {
  /* background: #fff; */
  /* padding: 8px; */
  /* width: 180px; */
  border-radius: 4px;
  /* margin: 0 17px; */
  /* margin-top: 7px; */
  margin-left: 10px;
  /* margin-top: 5px; */
  float: left;
}

.sign-logo {
  padding-bottom: 20px;
}

.sign-logo img {
  max-width: 180px;
}

.logo img {
  /* max-width: 100%;
    width: 240px; */
  max-width: 160px;
  width: 160px;
  height: 38px;
}

.userProfileMenuBox {
  min-width: 150px !important;
  /* margin-top: 52px !important; */
  max-height: inherit !important;
}

.topSettingsIcon {
  font-size: 1.5rem !important;
  line-height: 1.125rem !important;
}

.notificationSection {
  float: left;
  margin-right: 5px;
  position: relative;
  /* margin-top: 2px; */
}

.notificationSection .icon_bell .iconBell,
.userIcon {
  font-size: 1.875rem !important;
  color: #12afec !important;
}

.container {
  max-width: 95%;
  padding-right: 5px;
}

.notificationSection .badge {
  position: absolute;
  font-size: 0.625rem !important;
  top: 5px;
  background: red;
  /* z-index: 9; */
  right: 5px;
}

.lv-header {
  text-align: center;
  padding: 4px 10px 13px;
  line-height: 100%;
  text-transform: uppercase;
  border-bottom: 1px solid #f0f0f0;
  font-weight: 700;
  color: #a90f2d;
  margin-bottom: 0px;
}

.listData {
  padding: 10px;
  width: 100%;
  display: inline-block;
  border-bottom: 1px solid #eee;
}

.img {
  float: left;
}

.img img {
  border-radius: 100%;
  width: 50px;
  height: 50px;
}

.imgSqu img {
  width: 67px;
  height: 67px;
  border-radius: 0;
}

.textSec {
  float: left;
  padding-left: 12px;
  width: calc(100% - 50px);
}

.lv-title {
  font-weight: 700;
}

.lv-small {
  font-size: 0.75rem;
  color: #999;
}

.lv-footer {
  text-align: center;
  display: inline-block;
  width: 100%;
  font-weight: 600;
  font-size: 0.75rem;
  letter-spacing: 0.5px;
}

.notificationMenuBox {
  /* margin-top: 53px; */
  min-width: 180px !important;
}

.form-content {
  padding: 20px 0 5px 0;
}

.login-with-gw {
  /* background-color: #f5f5f5;
	background-image: url("./assets/img/gplus-icon.png");
	background-repeat: no-repeat;
	border: 1px solid #ccc;
	border-radius: 3px;
	color: #333;
	cursor: pointer;
	display: inline-block;
	font-size: 1rem;
	font-weight: 400;
	margin: 0; */
  padding: 8px 20px;
  /* text-decoration: none;
	vertical-align: baseline;
	white-space: nowrap;
	text-decoration:none; */
  width: 100%;
  max-width: 350px;
  background-position: center left 10px;
}

.login-with-google {
  background-color: #f5f5f5;
  background-image: url("./assets/img/gplus-icon.png");
  background-repeat: no-repeat;
  border: 1px solid #12afec;
  border-radius: 3px;
  color: #333;
  cursor: pointer;
  display: inline-block;
  font-size: 16px !important;
  font-weight: 400;
  margin: 0;
  padding: 8px 20px;
  text-decoration: none;
  vertical-align: baseline;
  white-space: nowrap;
  text-decoration: none;
  width: 100%;
  max-width: 350px;
  background-position: center left 10px;
}

.login-with-office {
  background-image: url("./assets/img/microsoft_icon.png");
}

.fa-windows:before {
  content: "\f17a";
}

.login-with-google:hover {
  background-color: #e9e9e9;
  border-color: #999;
  text-decoration: none;
  color: #222;
}

.btn-microsoft {
  color: #fff;
  background-color: #2672ec;
  border-color: rgba(0, 0, 0, 0.2);
}

.btn-social {
  position: relative;
  padding-left: 44px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.or {
  margin-top: 20px;
}

.loginMainDiv {
  /* background-color: #fff; */
  padding: 20px 30px;
  margin-bottom: 10px;
  /* border-radius: 4px; */
  /* box-shadow: 0 2px 2px rgba(10, 16, 20, .24), 0 0 2px rgba(10, 16, 20, .12); */
  box-sizing: border-box;
  transition: all 0.3s ease-in;
  position: relative;
  margin-top: 75px;
  max-width: 400px;
}

.whiteFormShodow {
  /* background-color: #fff; */
  padding: 20px 30px;
  margin-bottom: 10px;
  /* border-radius: 4px; */
  /* box-shadow: 0 2px 2px rgba(10, 16, 20, .24), 0 0 2px rgba(10, 16, 20, .12); */
  box-sizing: border-box;
  transition: all 0.3s ease-in;
  position: relative;
  margin-top: 75px;
  /* max-width: 400px; */
}

.signup-link {
  text-align: left;
  text-align: center;
  display: inline-block;
  width: 100%;
  padding: 7px 0px 4px 0;
  border-top: 1px solid #ddd;
  margin-top: 10px;
}

/*.leftMenu {
    width: 236px;
    position: fixed;
    top: 63px;
    left: 0;
	height:100%;
	 box-shadow: 3px 0 6px rgba(0,0,0,.24);
	-webkit-transition:all linear 0.5s;
	transition:all linear 0.5s;
	background-color: #fff;
}*/

/* .leftMenu {
    width: 270px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 100px;
    border: 8px solid white;
    border-image-source: url(assets/img/blr.png);
    border-image-slice: 20 20;
    border-image-repeat: round;
    padding-bottom: 100px;
} */

.mat-accordion.ng-star-inserted {
  /* display: block; */
  width: 100%;
  min-height: calc(100vh - 387px);
}

.leftMenu {
  /* width: 270px; */
  width: 292px;
  height: calc(100vh - 0px);
  overflow-x: hidden;
  padding-top: 100px;
  /* border: 8px solid white; */
  border-image-source: url(assets/img/blr.png);
  border-image-slice: 20 20;
  border-image-repeat: round;
}

.navGroups li {
  text-transform: capitalize;
  border-bottom: 0px solid rgba(0, 0, 0, 0.08);
}

.navGroups li a {
  /* color: rgba(255, 255, 255, 0.85); */
  color: #858585;
  display: block;
  font-size: 1.4em !important;
  font-weight: normal;
  letter-spacing: 0.8px;
  line-height: 18px;
  text-decoration: none;
  transition: all 0.3s;
  padding: 14px 30px;
  position: relative;
  cursor: pointer;
}

/* .navGroups li a.active {color: #f5ec48;} */

.navGroups li a.active,
.navGroups li a:hover,
.navGroups li a.active .mat-expansion-panel-header,
.navGroups li a.active .dropDownMenu .mat-expansion-panel-header-title,
.navGroups li a:hover i,
.navGroups li a.active i {
  background: #12afec;
  /* background: #a73223; */
  color: #ffffff;
  /* color: #f5ec48; */
  /* background-image: url(assets/img/active-menu.png); */
  background-size: cover;
  background-repeat: repeat-x;
}

.navGroups li a:hover img,
.navGroups li a.active img {
  filter: brightness(20);
}

.leftMenu .mat-expansion-panel-header.mat-expanded,
.dropDownMenu .mat-expansion-panel-header:hover {
  background: #a73223 !important;
  color: #ffffff !important;
  background-image: url(assets/img/active-menu.png) !important;
  background-size: contain !important;
}

.leftSubmenu li a:hover,
.leftSubmenu li a.active {
  background: transparent !important;
  color: #f5ec48;
  background-image: none !important;
}

.leftSubmenu li a.active .dropDownMenu .mat-expansion-panel-header-title {
  color: #f5ec48 !important;
}

.navGroups li a i {
  font-size: 16px !important;
  padding: 2px;
  margin-right: 0px;
  color: rgba(0, 0, 0, 0.54);
  width: 30px;
  display: inline-block;
  height: 30px;
  text-align: center;
}

.wrapper .mainContainer {
  /*width: calc(100% - 280px);
    margin-left: 260px;
    padding-top: 0px;
	padding: 1em 2em;*/
  /* padding: .5em 1.5em; */
  padding: 3.5em 0em 0.5em 0em;
  display: inline-block;
  width: 100%;
  min-height: calc(100vh - 64px);
  overflow: hidden;
}

.flag {
  float: left;
  margin-right: 20px !important;
  width: 28px !important;
}

.flag img {
  max-width: 100%;
  vertical-align: inherit;
}

/* .headerUserSec{ margin-top:50px;} */

.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 1px solid #fff;
  overflow: hidden;
}

.profile-pic img {
  max-width: 100%;
  vertical-align: initial;
}

.headerUserSec {
  max-width: 230px;
}

.headerUserSec ul.dropdown-user {
  padding: 0px;
}

.headerUserSec ul.dropdown-user li .dw-user-box {
  padding: 10px 15px;
}

.headerUserSec ul.dropdown-user li .dw-user-box .u-img {
  width: 70px;
  display: inline-block;
  vertical-align: top;
}

.headerUserSec ul.dropdown-user li .dw-user-box .u-img img {
  width: 100%;
  border-radius: 5px;
}

.headerUserSec ul.dropdown-user li .dw-user-box .u-text {
  display: inline-block;
  padding-left: 10px;
}

.headerUserSec ul.dropdown-user li .dw-user-box .u-text h4 {
  font-size: 18px !important;
  font-weight: 700;
  color: #333;
}

.headerUserSec ul.dropdown-user li.divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: rgba(120, 130, 140, 0.13);
}

.headerUserSec ul.dropdown-user li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.headerUserSec ul.dropdown-user li a {
  padding: 9px 15px;
  display: block;
  color: #333;
  cursor: pointer;
}

.headerUserSec ul.dropdown-user li a:hover {
  background: #f2f4f8;
  color: #1976d2;
  text-decoration: none;
}

.headerUserSec ul.dropdown-user li a i {
  margin-right: 12px;
  font-size: 18px !important;
  margin-top: 2px;
  float: left;
}

.leftSubMenu li a {
  padding-left: 43px;
}

.billAdd {
  font-weight: 700;
  text-align: left;
  width: 100%;
  padding-top: 20px;
  text-decoration: underline;
}

.radiusTopNone {
  border-radius: 0px 0 8px 8px;
}

.actionBtnGroup .mat-mini-fab .mat-button-wrapper {
  font-size: 18px !important;
  padding: 0px 0;
}

.mat-horizontal-stepper-header {
  height: 50px !important;
  /* padding: 0 20px !important; */
  padding: 0 16px !important;
}

.mat-horizontal-stepper-header-container {
  margin-bottom: 10px;
  /*
    border: 0;
    background-color: #fff;
    padding: 5px 10px;
    margin-bottom: 25px;
    border-radius: 4px;
    box-shadow: 0 2px 2px rgba(10,16,20,.24), 0 0 2px rgba(10,16,20,.12);
    box-sizing: border-box;
    transition: all .3s ease-in;
    */
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
  padding: 1px;
}

.mat-horizontal-content-container {
  padding: 0 !important;
  border: 0;
}

.border-all-side {
  border: 1px solid #ccc;
}

.mBtm10 {
  margin-bottom: 10px !important;
}

.mat-horizontal-stepper-content {
  overflow: initial !important;
}

.mat-horizontal-content-container {
  overflow: initial !important;
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
  overflow: hidden;
}

.mat-stepper-horizontal {
  background-color: transparent !important;
}

.gap-btn button {
  margin: 5px 5px;
}

.status2Radio {
  padding: 10px 0;
}

.status2Radio .lBl {
  padding-right: 5px;
}

.status2Radio.radio-btn label {
  margin-bottom: 0;
  vertical-align: middle;
}

.status2Radio .mat-radio-label {
  margin-left: 4px;
}

.status2Radio .mat-radio-label-content {
  padding-left: 5px !important;
}

.status2Radio.radio-btn .mat-radio-button {
  margin-left: 20px !important;
  margin-top: 0px !important;
  vertical-align: middle;
}

.header-strip {
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);
  box-sizing: border-box;
  background: #fff;
  padding: 10px 15px;
  margin-bottom: 15px;
  margin-top: 15px;
  border-radius: 4px;
}

.header-strip h4 {
  margin-top: 2px;
  margin-bottom: 0;
}

.formWhiteBox .mat-tab-body-content {
  padding: 0 1px;
}

.example-container1 {
  width: 100% !important;
  height: calc(100vh - 80px) !important;
  border: none !important;
}

.example-container2 {
  width: 100% !important;
  height: auto !important;
  border: none !important;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.disableScroll::-webkit-scrollbar {
  display: none !important;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  /* background:#e34e38; */
  background: #d8dde2;
}

.example-container {
  width: 100% !important;
  height: auto !important;
  min-height: calc(100vh - 80px);
  border: none !important;
}

.example-sidenav {
  padding: 0 !important;
  box-shadow: 3px 0 6px rgba(0, 0, 0, 0.24);
}

.leftToggleMenu {
  position: fixed;
  top: 20px;
  z-index: 4;
  left: 27px;
  font-size: 22px !important;
  color: #12afec;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.leftToggleMenu {
  position: fixed;
  top: 8px;
  /* z-index: 15; */
  left: 19px;
  font-size: 32px !important;
  color: #12afec;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  line-height: inherit;
}

.leftToggleMenu:hover,
.leftToggleMenu:focus,
.leftToggleMenu:active {
  outline: none;
}

.mat-drawer-container {
  background-color: #f9f9f9 !important;
  color: inherit !important;
}

.white-bg {
  background: #ffffff !important;
}

.mat-expansion-panel-header-title {
  color: #333 !important;
  /* font-size: 1rem;
    font-weight: 600; */
  font-size: 20px !important;
  font-weight: normal;
  letter-spacing: 0.8px;
  line-height: 21px;
}

.mat-drawer.mat-drawer-side .mat-expansion-panel-header-title {
  font-size: 16px !important;
  font-weight: normal;
  letter-spacing: 0.8px;
  line-height: 18px;
}

.mat-expanded .mat-expansion-panel-header-title {
  color: #a90f2d !important;
}

.width60-Center {
  max-width: 60px;
  text-align: center;
}

.width50 {
  max-width: 50px;
}

.width75 {
  max-width: 75px;
}

.width100 {
  max-width: 100px;
}

.width150 {
  max-width: 150px;
}

.width350 {
  max-width: 350px;
}

.width200 {
  max-width: 200px;
  min-width: 200px;
}

.width120 {
  max-width: 120px;
}

.width-100px {
  width: 100px !important;
}

.width-150 {
  width: 150px !important;
  padding: 2px;
}

.widthMin50 {
  min-width: 50px;
  /* padding: 2px; */
}

.widthMin75 {
  min-width: 75px;
  /* padding: 2px; */
}

.widthMin100 {
  min-width: 100px;
  /* padding: 2px; */
}

.widthMin150 {
  min-width: 150px;
  /* padding: 2px; */
}

.widthMin350 {
  min-width: 350px;
  /* padding: 2px; */
}

.widthMin200 {
  min-width: 200px;
  /* padding: 2px; */
}

.widthMin120 {
  min-width: 120px;
  /* padding: 2px; */
}

.padding-2px {
  padding: 2px;
}

.mat-expansion-panel-header-description {
  text-align: right;
  display: inline-block !important;
}

.tableRedTxtSubhead {
  color: #a90f2d !important;
  font-weight: 600;
  background: #f5f5f5;
}

.pageSubMenus {
  /* float: left; */
  width: 230px;
  background-color: #fff;
  box-shadow: 3px 0 6px rgba(0, 0, 0, 0.24);
  margin: -7px 20px 0px -22px;
  position: absolute;
  height: 100%;
}

.pageSubMenus .submenu_heading {
  font-weight: 600;
  padding: 10px 15px 10px 15px;
  margin-bottom: 0;
  background: rgba(0, 0, 0, 0.32);
  color: #fff;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 0.5px;
}

/* .rightMinSection {float: left; width: calc(100% - 230px); margin-left: 230px;} */

/* .rightMinSection {
    margin-left: 230px;
} */

.modal,
.modal-backdrop {
  /* z-index: 99; */
}

.smlLabel {
  font-size: 0.6875rem;
  color: #777;
  display: inline-block;
  width: 100%;
  padding-bottom: 5px;
}

.aLabel {
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

.boldGrayTitle {
  font-weight: 600;
  color: #646464;
}

/* Structure */

.example-header,
.tableSearchBar {
  min-height: 64px;
  display: flex;
  align-items: baseline;
  padding: 8px 24px 0;
  /* padding: 8px 15px 0;*/
  /* font-size: 1.25rem; */
  justify-content: space-between;
}

.tableSearchBar {
  padding: 8px 15px 0;
}

.action-buttons {
  display: inline-flex;
}

.example-header .mat-form-field,
.tableSearchBar .mat-form-field {
  padding-left: 15px;
}

.mat-form-field {
  position: static;
}

.fieldset_tab .mat-form-field-infix {
  max-width: 100%;
  width: 100%;
}

.example-header .button,
.tableSearchBar .button {
  padding-left: 15px;
}

.example-header .action-buttons,
.tableSearchBar .action-buttons {
  display: inline-flex;
}

.mat-form-field {
  /* font-size: .875rem; */
  flex-grow: 1;
  margin-left: 15px;
  width: 100%;
}

.mat-icon-button i {
  font-size: 1.125rem;
}

.tripsTable .mat-header-cell,
.tripsTable mat-cell {
  text-align: center;
}

.PrintTHC {
  position: absolute !important;
  top: 10px;
  right: 10px;
  font-size: 26px !important;
  line-height: 20px !important;
}

.consignmentPrint {
  padding: 0;
}

.consignmentPrint label,
.consignmentPrint p {
  margin-bottom: 0px;
}

.consignmentPrint table,
.consignmentPrint table mat-row mat-cell {
  border-color: #ddd;
}

.consignmentPrint table input[type="text"] {
  border: none;
  border-bottom: 1px solid #eee;
  box-sizing: border-box;
  margin: 10px 5px;
}

.printLogo {
  padding: 10px;
  max-width: 220px;
}

.printLogo img {
  max-width: 100%;
}

.consignmentPrint table input[type="checkbox"] {
  margin-top: 5px;
}

.consignmentPrint table textarea {
  width: 100%;
}

.consignmentPrint h2 {
  font-size: 1.375rem;
  font-weight: 800;
}

.consignmentPrint h3 {
  font-size: 1.1875rem;
  font-weight: 700;
}

.consignmentPrint h4 {
  font-size: 1.0625rem;
  font-weight: 700;
}

.consignmentPrint h4 {
  font-size: 1rem;
  font-weight: 700;
  padding: 10px 15px;
}

.consignmentPrint .borderAll {
  border: 1px solid #ccc;
  padding: 6px 15px;
}

.docsRecived {
  padding: 4px 10px;
}

.paddLeft15 {
  padding-left: 15px;
}

.paddRight15 {
  padding-right: 15px;
}

.consignmentPrint table,
.consignmentPrint table mat-row mat-cell,
.consignmentPrint table mat-row mat-header-cell {
  border-color: #ddd;
}

.consignmentPrint table input[type="text"] {
  border: none;
  border-bottom: 1px solid #eee;
  box-sizing: border-box;
  margin: 10px 5px;
  max-width: 100%;
}

.pad-all-10 {
  padding: 10px;
}

.pad-top-10 {
  padding-top: 10px;
}

.pad-right-10 {
  padding-right: 10px;
}

.pad-bottom-10 {
  padding-bottom: 10px;
}

.pad-left-10 {
  padding-left: 10px;
}

.mar-botm {
  margin-bottom: 0;
}

.tscTable {
  overflow: auto;
}

.pDate {
  display: inline-block;
  min-width: 120px;
}

.detailsTbl mat-header-cell {
  max-width: 100px;
}

.detailsTbl input[type="text"] {
  max-width: 100px;
}

.matChipGroup .mat-chip:not(.mat-basic-chip):focus {
  background-color: #3f51b5;
  color: #fff;
}

.mat-chip {
  font-size: 0.75rem;
  margin: 7px 2px !important;
}

.mat-tab-label,
.mat-tab-link {
  font-size: 0.875rem;
  line-height: 16px;
  font-weight: bold;
}

.successPagePopup .okIcon {
  color: #4ca33d;
  font-size: 3.75rem;
}

.successPagePopup h2 {
  color: #379626;
  font-weight: 700;
  font-size: 1.8125rem;
  padding: 0px 0px 5px 0;
}

.successPagePopup p {
  font-size: 1.375rem;
  margin-bottom: 0;
}

.successPagePopup button {
  margin-top: 20px;
  margin-bottom: 15px;
}

.mat-progress-spinner {
  display: block;
}

.fileTitle,
.chooseFile {
  display: inline-block;
  width: 190px;
  text-align: left;
}

.w-75.mat-form-field {
  float: left;
}

.modal-header {
  /* padding: 0px 0px; */
  display: flex;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  /* background-color: #F2F2F2; */
  border: none;
  /* padding-top: 0px; */
}

.modal-title {
  /* line-height: 1.17; */
  /* text-overflow: ellipsis; */
  /* overflow: hidden; */
  /* padding-top: 40px; */
  /* padding-bottom: 20px; */
  /* padding-left: 20px; */
}

.modal-header h2 {
  /* line-height: 1.17; */
  /* font-size: .875rem; */
  /* color: #343434; */
}

.modal-header .row {
  /* background-color: #fff; */
}

.whiteFormShodow .row .text-center .mat-warn {
  margin-right: 10px;
}

#loading-bar .bar {
  background: #ed223d;
}

#loading-bar-spinner {
  width: 100%;
  height: 100vh;
  background: rgba(252, 253, 253, 0.8);
  top: 0px;
  left: 0px;
}

#loading-bar-spinner .spinner-icon {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-top-color: #ed223d;
  border-left-color: #ed223d;
}

#loading-bar-spinner .spinner-icon {
  position: fixed !important;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 72px !important;
  height: 72px !important;
  border-top-color: inherit;
  border-top-style: solid;
  border-top-width: 8px;
  border-right-color: transparent;
  border-right-style: solid;
  border-right-width: 8px;
  border-bottom-color: transparent;
  border-bottom-style: solid;
  border-bottom-width: 8px;
  border-left-color: inherit;
  border-left-style: solid;
  border-left-width: 8px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
}

.center-screen {
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh; */
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  /* z-index: 98; */
  position: fixed;
}

.block-screen {
  width: 100%;
  height: 100%;
  z-index: 2000;
  position: fixed;
  left: 0;
  top: 0;
  /* right: 0;
bottom: 0; */
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  color: orange;
  font-size: large;
  font-weight: bolder;
  opacity: 0.8;
}

.podAttach {
  position: relative;
}

.podAttach:hover {
  background: rgba(0, 0, 0, 0.04);
}

.podAttach label {
  font-size: 1.0625rem;
  line-height: 40px;
  height: 40px;
  padding: 0 19px;
  width: 100%;
  font-weight: 400;
  margin-bottom: 0;
}

.podAttach input {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
}

/* .mat-form-field {float: left;} */

.selectRole {
  position: relative;
  float: left;
  margin-right: 10px;
}

.selectRole input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.selectRole label {
  display: inline-flex;
  padding: 7px 12px;
  border-radius: 24px;
  cursor: pointer;
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.8125rem;
  line-height: 18px;
  outline: 0;
}

.selectRole input[type="radio"]:checked + label {
  background-color: #3f51b5;
  color: #fff;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
}

.modal-dialog {
  max-width: fit-content;
  /* width: fit-content; */
  /* margin: 1rem; */
}

.mat-badge-content {
  color: #fff;
  background: #e34e38 !important;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -16px;
}

.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -3px;
}

.rightUserSec .mat-badge-medium .mat-badge-content {
  width: 20px;
  height: 20px;
  line-height: 18px;
  font-size: 0.6875rem;
  border: #f5ec48 1px solid;
}

.inne_area_list_l .mat-badge-content {
  color: #fff;
  background: #ff9c39 !important;
}

/* Responsive css start */

.mobileNone {
  display: flex;
}

.podAttach {
  position: relative;
}

.podAttach:hover {
  background: rgba(0, 0, 0, 0.04);
}

.podAttach label {
  font-size: 1.0625rem;
  line-height: 40px;
  height: 40px;
  padding: 0 19px;
  width: 100%;
  font-weight: 400;
  margin-bottom: 0;
}

.podAttach input {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
}

.header-strip .back-btn {
  width: 24px;
  height: 24px;
  display: inline-block;
  color: rgb(255, 255, 255);
  line-height: 22px;
  text-align: center;
  margin-right: 8px;
  padding-right: 2px;
  background: #3f51b5;
  border-radius: 50%;
}

input[type="radio"]:disabled + label {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 0.5;
}

.mapError {
  margin-top: -15px;
  font-size: 75%;
}

.userGroupsDropDown {
  padding-left: 40px;
  margin-top: 8px;
}

.m-t-8 {
  margin-top: 8px;
  display: inline-block;
}

.redheadTxt {
  color: #a90f2d;
  font-size: 1.25rem;
  font-weight: 400;
}

.bidsTable {
  padding: 10px 20px 20px 20px;
  background: #fff;
  display: inline-block;
  width: 100%;
}

.bidsTable ul {
  margin: 0;
  padding: 0;
}

.bidsTable ul li {
  float: left;
  width: 25%;
  padding: 4px 10px;
}

.bidsTable ul li .redheadTxt {
  padding-top: 15px;
  font-weight: 400;
  font-size: 1.25rem;
  padding-right: 30px;
}

.bidsTable ul li hr {
  width: 100%;
  display: inline-block;
  margin: 10px 0;
}

.bidsTable strong {
  float: left;
}

.bidsTable p {
  margin-bottom: 0;
  float: left;
  padding-left: 10px;
}

.groupUsersIcon img {
  max-width: 24px;
}

.footerPagBtnDiv {
  padding: 5px 20px;
  background: #fff;
  display: inline-block;
  width: 100%;
}

.btmMainWhiteDiv {
  background: #fff;
  padding: 10px;
}

.groupDetails .redheadTxt {
  padding-top: 0 !important;
  margin-bottom: 0;
  padding-right: 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
}

.groupDetails strong,
.groupDetails p {
  float: none;
  width: 100%;
  display: inline-block;
  padding-left: 0;
  padding-top: 5px;
}

/* .groupUsersTitle{padding: 15px 0 0 30px; margin-top:20px; margin-bottom: 10px;} */

.groupUsersTitle {
  padding: 0px 0 0 24px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.selectGroupBox {
  margin-top: 20px;
  margin-bottom: 1px;
  display: inline-block;
  width: 100%;
  background: #fff;
  padding: 10px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.selectGroupBox .groupBidLable {
  float: left;
  padding: 14px 10px 0px 0;
  color: #a91b48;
}

.selectGroupBox .w-75 {
  max-width: 300px;
}

.selectGroupBox .selectGroupBoxInner {
  width: 450px;
  margin: 0 auto;
}

/* .formWhiteBox .mat-tab-body-content { */

.mat-tab-body-content {
  overflow: inherit;
}

.mat-tab-body-content {
  height: 100%;
  overflow: inherit !important;
}

/* .mat-row:hover {
    background-color: rgb(245 236 72);
    border: 1px solid #ccc;
} */
/* .mat-row.active mat-cell:nth-child(1) {

    border: 1px solid #000;
} */
.mat_row_highlighted {
  background-color: rgba(0, 204, 255, 0.137);
}

span.mat-placeholder-required.mat-form-field-required-marker {
  /* font-weight: bolder; */
  color: tomato;
}

.fileUpload.required span:after {
  content: "*";
  color: white;
  padding-left: 1px;
}

/* label.mat-form-field-label.mat-input-placeholder.mat-form-field-placeholder {
    font-weight: bolder;
    color:yellow;
} */

/* span.mat-placeholder-required.mat-form-field-required-marker ::before {
    font-weight: 900;
    color:green;
} */

/* :required {
    background: red;
} */

/* input.mat-input-element:required {
    font-weight: bolder;
    color: #444;
} */

/* input.mat-input-element:invalid {
    background: plum;
    background: ivory;
    font-weight: bolder;
} */

/* mat-select.mat-select-required:invalid{
    font-weight: bolder;
    background: ivory;
  }
  mat-select.mat-select-required.mat-select-placeholder{
    font-weight: bolder;
    background: ivory;
  }
  mat-select.mat-select-required:invalid + span{
    font-weight: bolder;
    color: #444;
  } */

/* mat-select.ng-invalid.ng-untouched {
    font-weight: bolder;
    background: ivory;
    color: #444;
} */

/* mat-select.ng-invalid.ng-untouched .mat-select-placeholder{
    font-weight: bolder;
    color: #444;
  }
  mat-select.ng-valid:required +label{
    font-weight: bolder;
    color: #444;
  }
  input.mat-input-element.ng-invalid.ng-untouched{
    background: ivory;
    font-weight: bolder;
  }
   */

/* input:required:invalid + span {
    color: tomato;
    font-weight: bolder;
  } */

/* .mat-form-field-invalid .mat-form-field-label {
    color: #f44336;
    font-weight: bolder;
} */

/* .mat-form-field-required .mat-form-field-empty.mat-form-field-label {
    font-weight: bolder;
    background: ivory;
} */

/* mat-select.mat-select-required span.mat-select-placeholder {
    font-weight: bolder;
} */

.section-seperator {
  margin-bottom: 15px;
  margin-top: 15px;
  border-bottom-color: #03dac6;
  border-bottom-width: medium;
  border-bottom-style: solid;
  width: 100%;
  text-align: left;
  font-weight: bolder;
  font-size: larger;
}

.mat-raised-button {
  min-width: 50px;
}

/* span + span {
  margin-left: 10px;
} */

::ng-deep .cdk-overlay-container {
  z-index: 1060;
}

.cdk-overlay-container {
  z-index: 1070;
}

.pac-container {
  z-index: 1050;
}

@media screen and (max-width: 800px) {
  ::ng-deep .cdk-overlay-pane {
    right: unset !important;
  }
}

@media screen and (max-width: 500px) {
  mat-table.table_list mat-cell {
    padding: 5px !important;
  }

  .fieldset {
    padding: 5px !important;
  }

  .logo img {
    width: 180px;
  }
}

@media screen and (max-width: 400px) {
  mat-table.table_list mat-cell {
    padding: 5px !important;
  }

  .fieldset {
    padding: 5px !important;
  }

  .logo img {
    width: 160px;
  }
}

/*JINGLE CSS*/

button:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}

.tabIcon {
  width: auto;
  height: 20px;
  margin-right: 5px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-t-30 {
  margin-top: 30px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 0px !important;
  border-bottom: 1px solid #adadad !important;
  background: #fff !important;
}

.mat-form-field-appearance-standard .mat-form-field-flex {
  padding-top: 0 !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: transparent !important;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: transparent !important;
}

textarea.mat-input-element {
  margin: 4px 0 !important;
}

.jLabel {
  /* padding: 0px !important; */
  font-weight: 600 !important;
  color: #646464 !important;
  font-size: 13px !important;
  letter-spacing: 0.72px !important;
}

.jBoldLabel {
  /* padding: 0px !important; */
  font-weight: 600 !important;
  color: #646464 !important;
  font-size: 12px !important;
  letter-spacing: 0.3px !important;
  margin-bottom: 0px !important;
}

.borderSolid2 {
  border: 1px solid #c2c2c2;
  padding: 10px 10px;
  border-radius: 5px;
}

.leftMenu .mat-expansion-indicator::after {
  color: rgb(255, 255, 255) !important;
  /* transform: rotate(-45deg) !important; */
  padding: 4px !important;
}

.mat-tab-header,
.mat-tab-nav-bar {
  border-bottom: 0px solid #f1f1f1 !important;
}

.mat-tab-label {
  height: auto !important;
  padding: 0 10px !important;
  min-width: auto !important;
  border-bottom: 2px solid #f1f1f1 !important;
  letter-spacing: 0.72px;
  padding: 8px 30px !important;
}

.mat-tab-label-active {
  color: #12afec !important;
  background: rgba(255, 255, 255, 1) !important;
  /* opacity: 1 !important; */
  border-radius: 5px 5px 0px 0px !important;
  border-bottom: 4px solid !important;
}

.businessTypeTab .mat-tab-label-active {
  color: #12afec !important;
  background: #f3f3f3 !important;
  opacity: 1 !important;
  border-radius: 5px 5px 0px 0px !important;
  border-bottom: 2px solid !important;
}

.border-l-r {
  border-left: 1px solid #c6c6c6 !important;
  border-right: 1px solid #cacaca !important;
}

.mat-icon {
  color: #12afec !important;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #12afec !important;
  display: none !important;
}

.mat-button-toggle {
  border-bottom: 2px solid #ffffff;
  background: #fff;
  color: #adadad;
  min-width: 50px;
}

.mat-button-toggle-checked {
  border-bottom: 3px solid #12afec;
  background: #fff;
  color: rgb(18, 175, 236) !important;
}

.mat-button-toggle-checked .mat-icon {
  color: rgb(18, 175, 236);
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 30px !important;
  padding: 0 5px !important;
}

.mat-button-toggle-checked .mat-button-toggle-focus-overlay {
  border-bottom: none;
}

.mat-icon {
  color: #afafaf;
}

.mat-chip.mat-standard-chip {
  /* background-color: #f3f3f3 !important;
    color: #ababab !important; */
  min-height: 25px !important;
  /* padding: 5px 20px !important; */
}

.gray-chip {
  background-color: #f5f5f5 !important;
  color: #676767 !important;
}

.mat-checkbox-frame {
  /* border-color: rgb(18, 175, 236); */
  border-color: rgb(173, 173, 173);
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #12afec;
}

.blueOutlineBtn {
  border: 2px solid #12afec !important;
  border-radius: 30px !important;
  box-shadow: none !important;
  color: #12afec !important;
}

.blueOutlineBtn .mat-icon {
  color: #12afec !important;
}

.acceptReject button {
  padding: 5px 3px;
  line-height: 24px;
  margin-right: 5px;
}

.acceptReject button span {
  padding: 4px 30px !important;
}

.acceptReject button.active {
  background: #12afec !important;
  color: #fff;
  border-radius: 30px;
  padding: 5px 3px;
  line-height: 24px;
}

.acceptReject button.active span {
  border: 2px dotted #f5ec48 !important;
  border-radius: 30px !important;
  padding: 4px 30px !important;
}

.btnBorder {
  border: 2px dotted #f5ec48 !important;
  border-radius: 30px !important;
  padding: 4px 30px !important;
}

.blueButton {
  background: #12afec !important;
  color: #fff !important;
  /* border: 3px dotted #03719c !important; */
  border-radius: 40px !important;
  padding: 7px 3px !important;
  line-height: 21px !important;
}

.saveButton span {
  border: 2px dotted #f5ec48 !important;
  border-radius: 30px !important;
  padding: 4px 30px !important;
}

.blueButton .mat-icon {
  color: #ffffff;
  margin-top: 3px;
  position: absolute;
  margin-left: -24px;
}

.nOrdBtn {
  padding: 4px 15px 4px 15px !important;
}

.nOrdBtn .ti-plus {
  background: #0099d3;
  border-radius: 50%;
  font-size: 0.625rem;
  padding: 4px;
  line-height: 10px;
}

.addNewOrdBtn {
  background: #029ad4;
  border-radius: 50%;
  font-size: 0.6875rem;
  width: 21px !important;
  height: 21px !important;
  line-height: 23px;
  padding-left: 2px;
  position: absolute;
  margin-top: 1px;
  margin-left: -30px;
}

.newOrdBtn {
  /* font-size: 1rem; */
  letter-spacing: 0.54px;
  line-height: 22px !important;
}

.rowSaperator {
  border-bottom: 2px solid #dddddd;
  padding-bottom: 30px;
}

.menuIcon {
  height: 20px;
  margin-right: 10px;
}

.borderpic {
  border: #f5ec48 1px dashed;
  padding: 5px;
  border-radius: 100%;
}

.user-panel .user_img {
  width: 100%;
}

.user-panel .user_name {
  margin-bottom: 0px;
  font-weight: normal;
  color: #fff;
  font-size: 1.125rem;
  line-height: 20px;
  margin-top: 10px;
  letter-spacing: 1px;
}

.user-panel .user_company {
  margin-bottom: 20px;
  font-weight: 100;
  color: #f8ed43;
  font-size: 0.875rem;
  letter-spacing: 0.8px;
  line-height: 18px;
  margin-top: 10px;
}

.topbarSaperator {
  margin: 0 10px 0 5px;
  border: 0.5px solid #eb953e;
  padding: 8px 0;
}

.redTitle {
  color: #a73223;
  font-size: 1.625rem;
  font-weight: 600;
}

.topSearchBox .mat-icon {
  color: #a73223 !important;
}

.topSearchBox .mat-form-field-infix {
  color: #a73223 !important;
}

.topSearchBox .mat-form-field-underline {
  height: 6px !important;
  border-bottom: 0px dotted #f5ec48 !important;
  background-color: #a73223 !important;
}

.topSearchBox .mat-form-field-ripple {
  position: absolute;
  left: 0;
  width: 100%;
  transform-origin: 50%;
  transform: scaleX(1);
  opacity: 1;
  padding-top: 2px;
  border-bottom: 2px dotted #f5ec49;
  background: #a73223;
  transition: background-color 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
}

.addButton {
  background-color: #ffffff !important;
  border: 2px solid #12afec !important;
  margin-left: 10px !important;
}

.addButton .mat-button-wrapper {
  padding: 0px 0 !important;
}

.addButton .mat-icon {
  color: #12afec !important;
}

.dropDownMenu .mat-expansion-panel {
  background: transparent !important;
  color: rgb(255, 255, 255) !important;
  box-shadow: none !important;
}

.dropDownMenu .mat-expansion-panel-header {
  padding: 12px 30px !important;
  /* height: 45px !important; */
}

.dropDownMenu .mat-expansion-panel-body {
  padding: 0 !important;
}

.dropDownMenu .mat-expansion-panel-header-title {
  color: #ffffff !important;
}

.dropDownMenu .leftSubmenu a {
  padding-left: 50px !important;
  padding-right: 0px !important;
}

.dropDownMenu .leftSubmenu .mat-icon {
  height: 14px !important;
  width: 14px !important;
  margin-right: 5px !important;
  font-size: 12px !important;
  color: #ffffff !important;
}

.dboardIcon {
  width: 40px;
  margin-top: 10px;
}

.ordDetailsIcon {
  width: 40px;
  position: absolute;
}

.box1 {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  margin: 0.5rem 5px 0.5rem 5px;
  border-radius: 8px !important;
  padding: 15px 10px;
  background: #fff;
}

.box2 {
  margin: 0.5rem 0 2rem 0;
  border-radius: 8px !important;
  padding: 40px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
}

.placementSuccessRate-h6 {
  color: #12afec !important;
  margin-bottom: -30px;
  /* z-index: 97; */
  position: relative;
  padding-left: 20px;
  padding-top: 16px;
  width: 50%;
}

.headerTitle {
  font-size: 12px !important;
  line-height: 16px !important;
  font-weight: 500 !important;
  color: rgba(100, 100, 100, 0.7);
  letter-spacing: 0.72px;
  text-transform: none;
}

.shipmentBox {
  font-size: 0.875rem;
  line-height: 16px;
  font-weight: 400;
  color: rgba(100, 100, 100, 0.7);
  letter-spacing: 0.72px;
  text-transform: uppercase;
}

.blueTitle {
  font-size: 14px !important;
  line-height: 16px !important;
  font-weight: 600 !important;
  color: rgb(18, 175, 236);
  letter-spacing: 0.72px;
}

.degreeIcon::after {
  content: "\00b0";
}

.roundBlueIcon {
  font-size: 3.375rem;
  border: 2px solid #12afec;
  height: 75px !important;
  width: 75px !important;
  line-height: 70px;
  color: #12afec;
  border-radius: 50%;
  text-align: center !important;
}

.roundGrayIcon {
  font-size: 3.375rem;
  border: 2px solid #ababab;
  height: 75px !important;
  width: 75px !important;
  line-height: 70px;
  color: #ababab;
  border-radius: 50%;
  text-align: center !important;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #12afec !important;
  transition: opacity 2s ease-in !important;
  -moz-transition: opacity 2s ease-in !important;
  -webkit-transition: opacity 2s ease-in !important;
  /* display: none !important; */
}

.checkbox-mt-10 label {
  margin-top: 10px !important;
}

.mat-mini-fab:not([class*="mat-elevation-z"]) {
  box-shadow: none;
}

.gdatepicker .mat-form-field-flex {
  border: 1px solid #ddd;
  border-radius: 5px;
}

.gdatepicker .mat-form-field-underline {
  display: none !important;
}

.gdatepicker .mat-form-field-prefix {
  border-right: 1px solid #e0e0e0 !important;
  margin-right: 10px !important;
}

.mat-mini-fab {
  width: 30px !important;
  height: 30px !important;
  line-height: 24px !important;
}

.gdatepicker .mat-icon-button {
  color: #12afec !important;
  font-size: 24px !important;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.3375em solid transparent;
}

.formSearchBox {
  width: 265px;
}

.formSearchBox .mat-form-field-infix {
  line-height: 0.125 !important;
  padding: 0.4375em 0 1em 0 !important;
}

.formSearchBox .mat-form-field-suffix .mat-icon {
  color: #12afec !important;
}

/* .historyFavorite .mat-fab .mat-button-wrapper {
    padding: 0px;
}
.historyFavorite .mat-fab {
    background: #12afec !important;
    margin: 0 5px !important;
    position: relative !important;
    width: 30px !important;
    height: 30px !important;
    box-shadow: none !important;
    overflow: hidden !important;
}
.historyFavorite .mat-icon {
    color: #ffffff !important;
    margin-left: 25%;
    font-size: 30px !important;
    line-height: 10px !important;
    width: 30px !important;
}



.historyFavorite button .mat-button-wrapper {
    background: #12afec !important;
    display: grid;
    font-size: .75rem;
}
.historyFavorite button {padding: 0px;} */

.historyFavorite .mat-mini-fab .mat-button-wrapper {
  padding: 0px 0;
  display: inline-block;
  line-height: 24px;
}

.historyFavorite .mat-mini-fab {
  background-color: #12afec;
}

.historyFavorite .mat-icon {
  color: #fff;
}

.historyFavorite .fabBlueTitle {
  color: #12afec !important;
  font-size: 9px !important;
}

@media only screen and (max-width: 600px) {
  .fabBlueTitle span {
    display: none;
  }

  .col-md-4 {
    margin-bottom: 5px;
  }

  .roundBlueIcon {
    font-size: 2.25rem;
    border: 2px solid #12afec;
    height: 55px !important;
    width: 55px !important;
    line-height: 50px;
    color: #12afec;
    border-radius: 50%;
    text-align: center !important;
  }

  .roundGrayIcon {
    font-size: 2.25rem;
    border: 2px solid #ababab;
    height: 55px !important;
    width: 55px !important;
    line-height: 50px;
    color: #ababab;
    border-radius: 50%;
    text-align: center !important;
  }

  .historyFavorite {
    overflow: hidden;
  }
}

.m-t-m-40 {
  margin-top: -40px !important;
}

.m-b-m-45 {
  margin-bottom: -45px !important;
}

.m-t-m-5 {
  margin-top: -5px !important;
}

.m-l-m-5 {
  margin-left: -5px !important;
}

.timeToggle button {
  text-align: left !important;
}

.f-left {
  float: left !important;
}

.f-right {
  float: right !important;
}

.breakLabelToggle .mat-button-toggle-label-content {
  display: flex;
  line-height: 12px !important;
  white-space: normal !important;
  /* width: 117px !important; */
  text-align: left !important;
  padding-top: 6px !important;
  font-size: 13px !important;
}

.width-100 {
  width: 100% !important;
}

.width25 {
  width: 25px !important;
}

.mat-cell,
.mat-footer-cell {
  color: #343434;
}

/* @media (min-width: 1200px){
    .container {
        padding: 0px 70px;
    }
} */

.orderListing mat-cell {
  /* padding-top: 2em;
    padding-bottom: 2em;
    border-bottom-width: 0px; */
  padding-top: 1.7em;
  padding-bottom: 1.7em;
  /* border-bottom-width: 10px; */
  border-color: #f9f9f9;
  font-weight: 600;
}

.orderListing .mat-row {
  /* -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1); */
  /* border-radius: 8px; */
  height: 92px;
  letter-spacing: 0.88px;
  color: rgba(100, 100, 100, 0.7);
  background: #fff;
}

.orderListing .mat-row:hover {
  background-color: rgba(255, 255, 255, 1);
  /* box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.17);
    box-shadow: 0px 2px 18px 0px rgba(0,0,0,0.5); */
}

.orderListing .mat-row:before {
  box-sizing: border-box;
  content: "";
  position: absolute;
  left: 0;
  right: 2px;
  display: block;
  height: 90px;
  /* box-shadow: 0 1px 9px 1px rgba(0, 0, 0, 0.28); */
  /* -webkit-box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.28); */
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0px;
}

.orderListing .mat-header-row:before {
  box-sizing: border-box;
  content: "";
  position: absolute;
  left: 0;
  right: 2px;
  display: block;
  -webkit-transition: all 300ms ease-out 0s;
  -moz-transition: all 300ms ease-out 0s;
  -ms-transition: all 300ms ease-out 0s;
  -o-transition: all 300ms ease-out 0s;
  transition: all 300ms ease-out 0s;
  /* box-shadow: 0 1px 9px 1px rgba(0, 0, 0, 0.28); */
  -webkit-box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.28);
  border-radius: 0px;
  box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.28);
}

.orderListing .mat-row:hover:before {
  -webkit-transition: all 300ms ease-out 0s;
  -moz-transition: all 300ms ease-out 0s;
  -ms-transition: all 300ms ease-out 0s;
  -o-transition: all 300ms ease-out 0s;
  transition: all 300ms ease-out 0s;
  /* box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.50); */
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.17);
  border-radius: 0px;
}

.orderListing mat-cell:last-of-type {
  padding-right: 0px !important;
  border-left: 1px solid #cecece;
}

.mat-progress-bar-fill {
  transform: scaleX(0.5);
}

.mat-progress-bar-fill:after {
  background-color: #12afec;
}

.orderListing mat-header-cell {
  border-bottom-width: 0px;
}

.orderListing .mat-tab-label {
  padding: 0 30px 0 20px !important;
  font-weight: 500 !important;
  color: #a7a7a7;
  letter-spacing: 0.72px;
  opacity: 1;
}

.orderListing .mat-tab-body,
.orderListing mat-table {
  background: transparent;
  padding-left: 5px;
  padding-right: 5px;
}

.orderListing .mat-tab-label {
  border-bottom: 2px solid transparent !important;
}

.orderListing .mat-tab-label-active {
  border-bottom: 2px solid #12afec !important;
}

.orderListing .mat-tab-header {
  border-bottom: 2px solid #e1e1e1 !important;
}

.mat-PendingApproval-bg {
  background-color: #e8715f !important;
  color: #ffffff !important;
}

.mat-InTransit-bg {
  background-color: #6dcdf3 !important;
  color: #ffffff !important;
}

.mat-Upcoming-bg {
  background-color: #fbbe45 !important;
  color: #ffffff !important;
}

.mat-PendingQuote-bg {
  background-color: #d6d6d6 !important;
  color: #7b7b7b !important;
}

.mat-Accept-bg,
.mat-OnTime-bg {
  background-color: #95da48 !important;
  border: 1px solid transparent;
  color: #ffffff !important;
}

.mat-Reject-bg {
  background-color: #ffffff !important;
  border: 1px solid #838383;
  color: #838383 !important;
}

.vertBlueIcon {
  color: #12afec;
}

.AssignTruckToggle {
  border: solid 0px rgba(0, 0, 0, 0.12) !important;
}

.AssignTruckToggle.radius20 .mat-button-toggle {
  border-radius: 36px;
  padding: 15px 20px;
}

.mat-button-toggle-group,
.mat-button-toggle-standalone {
  box-shadow: none;
}

.mat-column-cbox {
  max-width: 40px;
  overflow: visible;
  padding: 10px !important;
  background: none !important;
}

.mat-paginator {
  margin-left: auto;
  width: 92.2%;
}

.table_list mat-footer-row,
.table_list mat-header-row,
.table_list mat-row {
  border-bottom-width: 0px;
  padding-right: 5px;
}

mat-table.table_list mat-row mat-cell.mat-column-cbox:after {
  border: none;
  background: none;
}

.inne_area_list_r h1 {
  font-size: 1.5rem;
  color: #12afec;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 15px;
  /* margin-top: 18px; */
}

.AssignTruckToggle .mat-button-toggle {
  border: none;
  box-shadow: none;
  margin-top: 10px;
  color: #343434;
  margin-bottom: 10px;
  margin-right: 15px;
  margin-left: 3px;
  border-radius: 5px;
}

.AssignTruck {
  font-size: 0.875rem;
  font-weight: 400;
  padding: 0 5px 5px 5px;
}

.AssignTruck span {
  font-size: 2rem;
  font-weight: 400;
  margin: 0 15px 0 15px;
  position: relative;
  bottom: -7px;
}

/* .mat-button-toggle:hover,
.mat-button-toggle.active, .mat-button-toggle-checked {
    background: #12afec;
    color: #fff !important;
} */
.AssignTruckToggle .mat-button-toggle-checked {
  background: #12afec !important;
  color: #fff !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #12afec;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #12afec;
}

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label {
  /* display: none !important; */
  color: #adadad;
  /* font-size: .725rem; */
  font-size: 11px;
}

.orderDetails .mat-expansion-panel-header .mat-content,
#orderTripDetails .mat-expansion-panel-header .mat-content {
  display: inline-flex !important;
}

.orderDetails .mat-expansion-indicator::after,
#orderTripDetails .mat-expansion-indicator::after {
  margin-top: -5px !important;
  color: #12afec !important;
}

.orderDetails .mat-expansion-indicator,
#orderTripDetails .mat-expansion-indicator {
  padding: 8px 13px;
  border-radius: 50%;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.13);
}

.shareDetailBtn,
.shareDetailBtn .mat-icon {
  background: #12afec !important;
  color: #fff !important;
}

.excelDownload {
  position: absolute !important;
  right: 0 !important;
  margin-top: 25px !important;
  background: #12afec !important;
  color: #fff !important;
  padding: 0px 25px !important;
}

.excelDownload .fa-download {
  margin: 0 7px;
}

.tripDetailsBtnLink {
  position: absolute !important;
  right: -25px !important;
  top: 150px !important;
  /* z-index: 96 !important; */
}

.tripDetailsBtnLink .mat-icon {
  color: #12afec !important;
}

.notificationSection .icon_bell .iconBell,
.userIcon {
  font-size: 36px !important;
  color: #12afec !important;
  margin-right: 15px;
}

@media screen and (max-width: 767px) {
  body #TopB-T {
    background: #f9f9f9;
  }

  body .details_list_item h2 {
    padding-top: 0;
  }

  #AdmindetailsGW.notShowDetails .details_list_item {
    display: block;
  }

  .mobileNone {
    display: none !important;
  }

  #AdminlistGW.d-Bnone {
    position: relative;
    left: -100%;
    transition: all 0.6s;
    display: none;
  }

  #B-slider > div {
    padding: 10px 0px !important;
  }

  #TopB-T .col-md-6,
  #TopB-T .container,
  #TopB-T .row {
    margin: 0px auto;
    max-width: 100%;
    padding: 0;
  }

  /* .inne_area_list_r {} */
  .AssignTruckToggle .mat-button-toggle {
    padding: 15px 3px;
  }

  .wrapper .header {
    /* padding: 5px 20px 86px 20px;
    height: 64px; */
    padding: 5px 20px 50px 20px;
    height: 60px;
  }

  .leftToggleMenu {
    top: 8px;
  }

  .headerSearchBar_se {
    display: none;
  }

  .notificationSection .mat-icon-button {
    padding: 0;
    min-width: 0;
    width: 27px;
    height: 21px;
  }

  .notificationSection .blueButton {
    padding: 0;
    min-width: 0;
    width: 27px;
    /* height: 21px; */
  }

  .notificationSection .icon_bell .iconBell,
  .userIcon {
    font-size: 30px !important;
    color: #12afec !important;
    margin-right: 0px !important;
  }

  .notificationSection .userIcon {
    font-size: 28px !important;
    color: #12afec !important;
    margin-right: 0px !important;
    height: unset;
    line-height: 1;
  }

  .blueButton.newOrdBtn {
    position: relative;
    padding: 0 !important;
    min-width: auto;
  }

  .blueButton.newOrdBtn:after,
  .blueButton.newOrdBtn:before {
    position: absolute;
    height: 2px;
    top: 0px;
    width: 14px;
    content: "";
    display: block;
    background: #0099d3;
  }

  .blueButton.newOrdBtn:before {
    transform: rotate(90deg);
  }

  .blueButton.newOrdBtn > span,
  .blueButton.newOrdBtn > div {
    display: none;
  }

  .ng-star-inserted > .container > .p-l-20.p-r-20 {
    padding: 0 !important;
  }

  .orderListing .row {
    margin: 0 !important;
  }

  .orderListing .row .mobScroll {
    padding: 0 !important;
  }

  .mob-p-default .box2 {
    padding: 8px;
  }

  .mob-p-default .box2 .col-md-8 {
    padding-right: 0px;
  }

  .mob-p-0 .row.col-md-4.text-right {
    margin: 0px;
  }

  .orderListing.p-0 {
    padding: 0px 10px !important;
  }

  .leftToggleMenu {
    left: 4px;
    font-size: 18px !important;
    top: 9px;
  }

  .logo {
    width: 220px;
    /* margin-left: 33px; */
    margin-left: 25px !important;
    margin-top: 5px;
    line-height: 13px;
  }

  .logo img {
    width: 100px;
    height: 20px;
  }

  .wrapper .mainContainer {
    /* padding: 30px 0px 10px 0px; */
    padding: 0;
  }

  .header-strip h4 {
    margin-top: 0px;
    margin-bottom: 8px;
    font-size: 1.25rem;
    font-weight: 700;
    color: #333;
    text-align: center;
  }

  .header-strip {
    margin-top: 5px;
  }

  .header-strip .text-right {
    text-align: center !important;
  }

  .mat-drawer-content {
    width: 100%;
  }

  .example-container {
    min-height: inherit;
  }

  .mat-elevation-z8 .col-sm-3.mTop15,
  .mat-elevation-z8 .col-sm-3.mTop10 {
    margin-top: 0px;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .details_list_item {
    display: none;
  }

  .mainContainer .AssignTruckToggle .mat-button-toggle {
    text-align: center;
    padding: 5px 4px;
    /* padding: 10px 20px; */
  }

  .content_area_list > .container {
    max-width: 100%;
  }

  /* .content_area_list .mob-p-default,
    .top-section div {
        margin: 0!important;
        padding: 0px!important;
    } */

  .mat-form-field {
    width: 100%;
  }

  .rowSaperator {
    border-bottom: 0px solid #dddddd;
  }

  .mobBorderNone {
    border: 0px;
  }

  iframe {
    border: 2px solid #12afec;
    border-radius: 5px;
  }

  .mob-m-default {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }

  .mob-p-default {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }

  .inne_area_list_l {
    border-right: none !important;
    /* padding: 5px !important; */
    padding: 0px 0px 0px 5px !important;
  }

  .example-container1 {
    width: 100% !important;
    height: calc(100vh - 80px) !important;
    border: none !important;
    /* padding: 0px 5px 0px 5px; */
    padding-left: 5px;
  }

  .mob-p-0 {
    padding: 0px !important;
  }

  .mob-m-0 {
    margin: 0px !important;
  }

  /* .mat-button-toggle-group {display: grid !important;} */
  .text-right {
    right: 5px !important;
  }

  .header {
    padding: 5px 20px 135px 20px;
    background-size: auto 100%;
  }

  .formSearchBox {
    margin: 0px !important;
    padding: 0px !important;
    width: 100% !important;
  }

  .historyFavorite {
    position: absolute !important;
    right: 0 !important;
    width: 150px !important;
  }

  .AssignTruckToggle {
    overflow: auto !important;
    width: 100% !important;
  }

  .rightUserSec {
    position: absolute !important;
    right: 0 !important;
    top: 0 !important;
    margin: 5px;
  }

  .headerSearchBar {
    border-left: none !important;
    margin: 5px 20px 10px 0;
    padding: 0px 40px 0px;
  }

  .advancesearchbox {
    margin: 10px 0px !important;
  }

  .form-searchbar {
    width: 150px !important;
  }

  .topbarSaperator {
    display: none !important;
  }

  .mobScroll {
    overflow: auto !important;
    /* width: 100% !important; */
    min-height: 40px !important;
    /* height: 40px; */
  }

  .orderListing mat-cell {
    min-width: 100px !important;
  }

  .mat-form-field-wrapper {
    /* width: 320px !important; */
    width: 100% !important;
  }

  mat-cell:first-of-type,
  mat-cell.mat-footer-cell:first-of-type,
  mat-header-cell:first-of-type {
    padding-left: 10px !important;
    min-width: 50px !important;
  }

  mat-cell:first-of-type.mat-column-cbox,
  mat-cell.mat-footer-cell:first-of-type.mat-column-cbox,
  mat-header-cell:first-of-type.mat-column-cbox {
    max-width: 30px !important;
    min-width: 20px !important;
    overflow: visible;
    padding: 0px !important;
  }

  /* .mat-column-cbox{
        max-width: 30px !important;
        min-width: 20px !important;
        overflow: visible;
        padding: 0px !important;

    } */
  .orderListing mat-cell:last-of-type {
    min-width: 50px !important;
  }

  .AssignTruck span {
    font-size: 16px !important;
    margin: 0px !important;
    bottom: 0px !important;
  }

  .AssignTruckToggle .mat-button-toggle {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    margin-right: 5px !important;
    margin-left: 5px !important;
    /* border-radius: 5px !important; */
  }

  .redTitle {
    font-size: 18px !important;
  }

  .mat-chip-list-wrapper {
    min-width: 150px !important;
  }

  .mat-expansion-panel-body {
    padding: 0 0px 0px !important;
  }

  .detailsPageBtn {
    width: 48%;
    float: left;
  }

  .mob-select {
    width: 88% !important;
  }

  .tripDetailsBtnLink {
    right: 12px !important;
    top: -6px !important;
    border: 1px solid #12afec !important;
    border-radius: 0px !important;
  }

  .placementSuccessRate-h6 {
    margin-bottom: 0px;
    padding-top: 0px;
  }

  .orderListing mat-cell:last-of-type {
    border-left: 0px solid #cecece !important;
  }

  .pb-3,
  .py-3,
  .pb-4,
  .py-4 {
    padding-bottom: 0rem !important;
  }

  .m-t-10 {
    margin-top: 1px !important;
  }

  .task-heading {
    display: none;
  }

  .p-l-20 {
    padding-left: 5px !important;
  }

  .p-r-10 {
    padding-right: 5px !important;
  }

  /* .mob-p-default{

    } */

  .no-mobile {
    display: none;
  }

  .s-mobile {
    display: block !important;
  }

  .mat-fab.mat-primary,
  .mat-flat-button.mat-primary,
  .mat-mini-fab.mat-primary,
  .mat-raised-button.mat-primary {
    font-size: 12px !important;
    line-height: 25px;
    padding: 0px 5px;
  }

  .col-sm-12 .text-right {
    right: 40px !important;
    padding: 0px !important;
  }

  .mobile-provider > .mat-button-toggle-group {
    display: grid !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  body {
    font-size: 0.6875rem;
  }

  iframe {
    border: 2px solid #12afec;
    border-radius: 5px;
  }

  .mob-p-0 {
    padding: 0px !important;
  }

  .mob-m-0 {
    margin: 0px !important;
  }

  .wrapper .mainContainer {
    padding: 5.5em 0em 0em 0em;
  }

  .mobScroll {
    overflow: auto !important;
    /* width: 100% !important; */
  }

  .orderListing mat-cell {
    min-width: 100px !important;
  }

  mat-cell:first-of-type,
  mat-footer-cell:first-of-type,
  mat-header-cell:first-of-type {
    padding-left: 10px !important;
    min-width: 50px !important;
  }

  .orderListing mat-cell:last-of-type {
    min-width: 50px !important;
  }

  .mat-chip-list-wrapper {
    min-width: 150px !important;
  }

  .recentlyAddedDropdown {
    position: absolute !important;
    right: 20px;
    font-size: 0.75rem;
    width: 100px !important;
  }

  .orderListing mat-cell:last-of-type {
    border-left: 0px solid #cecece !important;
  }
}

/* {
    position: fixed;
    bottom: 20px;
    width: 284px;
    background: #e34e38;
} */

.paymentsMiddleTabs .mat-tab-header {
  position: absolute;
  /* z-index: 95; */
  right: 15px;
  top: 5px;
}

.paymentsMiddleTabs .mat-tab-label {
  font-size: 0.75rem;
  line-height: 13px;
  letter-spacing: 0.48px !important;
  font-weight: normal;
  height: 36px !important;
}

.paymentsMiddleTabs .mat-tab-body {
  padding: 0px;
  background: transparent;
}

.quoteDetailsTable {
  width: 100%;
}

.quoteDetailsTable .mat-row {
  background-color: transparent !important;
  border-left: 5px solid transparent;
}

.quoteDetailsTable .mat-row:hover {
  background-color: rgba(0, 204, 255, 0.137) !important;
  border-left: 5px solid #12afec;
}

.quoteDetailsTable mat-table {
  border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.mat-button-toggle-checked .mat-button-toggle-focus-overlay {
  border-bottom: solid 42px;
}

.bottomImg {
  position: relative;
  bottom: 0px;
  padding-bottom: 20px;
  width: 250px;
  padding-top: 12px;
  /* background: #e34e38; */
  margin: auto;
}

.mat-expanded .mat-expansion-panel-header-title {
  color: #f5ec48 !important;
}

.mat-expanded .mat-expansion-indicator::after {
  color: rgb(245, 236, 72) !important;
}

.left-sidenav .mat-expansion-panel {
  background: transparent;
  color: rgb(250, 250, 250);
  overflow: visible;
}

.left-sidenav .mat-expansion-panel-header-title {
  color: rgb(250, 250, 250) !important;
}

.left-sidenav .mat-expansion-panel-spacing {
  margin: 0px 0;
}

.left-sidenav .mat-expansion-panel {
  border-radius: 0px;
}

.left-sidenav .mat-accordion .mat-expansion-panel:first-of-type {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

.left-sidenav .mat-accordion .mat-expansion-panel:last-of-type {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.left-sidenav .mat-expansion-panel:not([class*="mat-elevation-z"]) {
  box-shadow: none;
}

.singleMenuLink .mat-expansion-panel-content,
.singleMenuLink .mat-expansion-indicator {
  display: none !important;
}

.left-sidenav .singleMenuItem {
  height: 48px;
  padding: 0 24px;
  font-size: 0.9375rem;
  font-weight: 400;
  background: transparent;
  color: rgb(250, 250, 250);
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
}

.submenu li a {
  height: 36px;
  padding: 0 0px;
  font-size: 0.9375rem;
  font-weight: 400;
  background: transparent;
  color: rgb(250, 250, 250);
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
}

.submenu li a:hover,
.submenu li a.active,
.submenu li a.active .mat-icon,
.submenu li a:hover .mat-icon {
  color: #f5ec48;
}

.submenu .mat-icon {
  color: #ffffff;
}

.submenu .material-icons {
  font-size: 0.75rem;
  line-height: 26px;
}

.left-sidenav .singleMenuItem.active,
.left-sidenav .singleMenuItem:hover,
.left-sidenav .singleMenuLink.active,
.left-sidenav .singleMenuLink:hover,
.left-sidenav .mat-expansion-panel.active .mat-expansion-panel-header,
.left-sidenav .mat-expanded .mat-expansion-panel-header,
.left-sidenav .mat-expansion-panel-header:hover {
  background: #a73223;
  color: #f5ec48 !important;
  background-image: url(assets/img/active-menu.png) !important;
  background-size: contain !important;
}

.left-sidenav .mat-expansion-panel-header:hover .mat-expansion-indicator::after,
.left-sidenav .mat-expansion-panel.active .mat-expansion-indicator::after {
  color: #f5ec48 !important;
}

.left-sidenav .mat-expanded .mat-expansion-panel-header-title,
.left-sidenav .mat-expansion-panel.active .mat-expansion-panel-header-title,
.left-sidenav
  .mat-expansion-panel-header:hover
  .mat-expansion-panel-header-title {
  color: #f5ec48 !important;
}

.left-sidenav .singleMenuItem:hover .mat-expansion-panel-header-title,
.left-sidenav .singleMenuLink.active .mat-expansion-panel-header-title {
  color: #f5ec48 !important;
}

.submenu li a.active .left-sidenav .mat-panel-title {
  color: #f5ec48 !important;
}

.dashboardMenu,
.ordersMenu,
.tripsMenu,
.paymentsMenu,
.reportsMenu {
  width: 30px;
  height: 30px;
  margin-right: 4px;
  background-position: 0px 2px;
  background-size: 24px;
  background-repeat: no-repeat;
}

.dashboardMenu {
  background-image: url(assets/img/icons/dashboardw.png);
}

.dMenu.active .dashboardMenu,
.dMenu:hover .dashboardMenu {
  background-image: url(assets/img/icons/dashboardy.png);
}

.ordersMenu {
  background-image: url(assets/img/icons/ordersw.png);
}

.left-sidenav .mat-expanded .ordersMenu,
.oMenu.active .ordersMenu,
.oMenu:hover .ordersMenu {
  background-image: url(assets/img/icons/ordersy.png);
}

.tripsMenu {
  background-image: url(assets/img/icons/tripsw.png);
}

.left-sidenav .mat-expanded .tripsMenu,
.tMenu.active .tripsMenu,
.tMenu:hover .tripsMenu {
  background-image: url(assets/img/icons/tripsy.png);
}

.paymentsMenu {
  background-image: url(assets/img/icons/paymentw.png);
}

.left-sidenav .mat-expanded .paymentsMenu,
.pMenu.active .paymentsMenu,
.pMenu:hover .paymentsMenu {
  background-image: url(assets/img/icons/paymenty.png);
}

.reportsMenu {
  background-image: url(assets/img/icons/reportsw.png);
}

.left-sidenav .mat-expanded .reportsMenu,
.rMenu.active .reportsMenu,
.rMenu:hover .reportsMenu {
  background-image: url(assets/img/icons/reportsy.png);
}

.mat-select-arrow {
  width: 10px !important;
  height: 8px !important;
  border-left: 0px solid transparent !important;
  border-right: 0px solid transparent !important;
  border-top: 0px solid !important;
  margin: 0 4px !important;
  background: url(assets/img/arrowRop_down.png) 0px 0px no-repeat;
}

.selectBox .mat-form-field-underline {
  background-color: transparent !important;
  border: 0px dashed #12afec !important;
}

.selectBox .mat-form-field-wrapper {
  border: 1px solid #12afec !important;
  border-radius: 5px !important;
  padding: 5px !important;
}

.selectdiv {
  position: relative;
  float: left;
}

select::-ms-expand {
  display: none;
}

.selectdiv:after {
  content: "<>";
  font: 17px "Consolas", monospace;
  color: #12afec;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  right: 0px;
  position: absolute;
  pointer-events: none;
}

.DRYBusinessType {
  background: url(/assets/img/dryg.png) 0px 0px no-repeat;
  height: 27px;
  width: 24px;
  display: inline-block;
  background-size: contain;
}

.DRYBusinessType:hover {
  background: url(/assets/img/dryg.png) 0px 0px no-repeat;
  background-size: contain;
}

.EXIMBusinessType {
  background: url(/assets/img/eximg.png) 0px 0px no-repeat;
  height: 27px;
  width: 24px;
  display: inline-block;
  background-size: contain;
}

.EXIMBusinessType:hover {
  background: url(/assets/img/eximg.png) 0px 0px no-repeat;
  background-size: contain;
}

.REEFERBusinessType {
  background: url(/assets/img/reeferg.png) 0px 0px no-repeat;
  height: 27px;
  width: 24px;
  display: inline-block;
  background-size: contain;
}

.REEFERBusinessType:hover {
  background-image: url(/assets/img/reeferg.png) 0px 0px no-repeat;
  background-size: contain;
}

.details_list_item .mat-tab-label {
  width: 176px;
  height: 56px;
  font-weight: initial;
}

.mat-tab-label-active .DRYBusinessType {
  background: url(/assets/img/dry.png) 0px 0px no-repeat;
  background-size: contain;
}

.mat-tab-label-active .EXIMBusinessType {
  background: url(/assets/img/exim.png) 0px 0px no-repeat;
  background-size: contain;
}

.mat-tab-label-active .REEFERBusinessType {
  background: url(/assets/img/reefer.png) 0px 0px no-repeat;
  background-size: contain;
}

.borderBottom2 {
  border-bottom: 2px solid #e1e1e1;
}

.mat-tab-body {
  background: #fff;
  padding: 5px;
  border-radius: 10px;
}

.details_list_item {
  display: block;
  margin-top: 22px;
}

.details_list_item .mat-tab-body {
  background: #fff;
  padding: 0px !important;
  border-radius: 0px 10px 10px;
}

.details_list_item .modal-header {
  border: none;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* padding: 15px; */
}

.details_list_item h3 {
  font-size: 1rem;
}

.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mat-button-toggle-checked .mat-button-toggle-focus-overlay {
  border-bottom: solid 36px transparent !important;
}

.orderDetails .mat-expansion-panel {
  background: transparent !important;
  color: rgba(0, 0, 0, 0.87);
}

.orderDetails .mat-expansion-panel-body {
  padding: 0 15px 15px;
}

.bgNone {
  background-color: transparent !important;
}

.mat-tab-label-active g,
.mat-tab-label-active svg,
.mat-tab-label-active svg path,
.mat-button-toggle-checked g,
.mat-button-toggle-checked svg,
.mat-button-toggle-checked svg path,
.mat-button-toggle:hover g,
.mat-button-toggle:hover svg,
.mat-button-toggle:hover svg path {
  stroke: #12afec;
  fill: #12afec;
}

.mat-button-toggle svg {
  margin-right: 5px;
}

.newOrderForm .mat-tab-label {
  border-right: 1.5px solid rgba(100, 100, 100, 0.07);
  border-radius: 0px !important;
}

.mat-tab-body .mat-tab-label {
  border-right: 0px solid rgba(100, 100, 100, 0.07);
  border-radius: 0px !important;
}

/************search section syed shujait modify****************/

.headerSearchBar {
  padding: 5px 15px;
}

.headerSearchBar i {
  font-size: 1.125rem;
}

.headerSearchBar {
  float: left;
  margin: 5px 20px 10px 0;
  border-left: #a73223 1px solid;
  position: relative;
  padding: 0px 0px 0px 40px;
}

.form-searchbar {
  background: rgba(0, 0, 0, 0) url(assets/img/Glasswing-Dashboard-Updated.jpg)
    0px 100% repeat-x;
  border: none;
  padding: 5px 10px 13px 26px;
  color: #a73223;
  width: 230px;
  max-width: 100%;
  height: auto;
}

.form-searchbar i {
  font-size: 1.25rem;
}

.headerSearchBar .form-control {
  border-radius: 100px;
}

.headerSearchBar .search-btn {
  position: absolute;
  left: 43px;
  color: #a73223;
  font-weight: 600;
  top: 9px;
}

.advancesearchbox {
  border-radius: 40px;
  margin: 10px 15px;
  float: left;
  background: #a73223;
  padding: 2px;
}

.advancesearchbox .addSearch {
  border: dashed #f5ec48 1px;
  border-radius: 40px;
  font-size: 0.875rem;
  color: #fff;
  text-decoration: none;
  display: block;
  padding: 5px 20px;
  cursor: pointer;
}

/* .leaflet-marker-icon{border-radius: 100%; background: #f5ec48;padding:2px;height:42px!important;width: 42px!important;box-shadow: rgba(0,0,0,0.6) 0px 0px 8px; } */

.leaflet-marker-icon img {
  height: 68px;
  width: auto;
}

.mat-drawer-content {
  overflow: visible !important;
}

/*********************syed shujait******************/

.AssignTruckToggle .mat-button-toggle {
  text-align: center;
  padding: 15px 30px;
  min-width: auto;
}

.AssignTruckToggle .mat-button-toggle .mat-button-toggle-label-content {
  display: block;
  padding: 0;
}

.AssignTruckToggle .mat-button-toggle .AssignTruck {
  display: block;
  text-align: left;
  padding: 0px;
  line-height: normal;
}

.AssignTruckToggle .mat-button-toggle .AssignTruck span {
  width: 29px;
  display: inline-block;
}

.width30 {
  width: 30px;
}

.font-size18 {
  font-size: 1.125rem;
}

.mat-tab-labels {
  display: flex;
}

.mat-tab-label .mat-tab-label-content {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.mat-checkbox-inner-container-no-side-margin {
  margin-left: 0;
  margin-right: 0;
}

.mat-checkbox .mat-checkbox-ripple {
  position: absolute;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  height: 40px;
  width: 40px;
  /* z-index: 1; */
  pointer-events: none;
}

.mat-checkbox-inner-container {
  display: inline-block;
  height: 16px;
  line-height: 0;
  margin: auto;
  margin-right: 8px;
  order: 0;
  position: relative;
  vertical-align: middle;
  white-space: nowrap;
  width: 16px;
  flex-shrink: 0;
}

.mat-checkbox-frame {
  background-color: transparent;
  transition: border-color 90ms cubic-bezier(0, 0, 0.2, 0.1);
  border-width: 2px;
  border-style: solid;
}

.mat-checkbox-background,
.mat-checkbox-frame {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  border-radius: 2px;
  box-sizing: border-box;
  pointer-events: none;
}

.orderListing .mat-row {
  /* border-radius: 8px; */
  height: 92px;
  letter-spacing: 0.88px;
  color: rgba(100, 100, 100, 0.7);
  background: #fff;
}

mat-cell:first-of-type,
mat-footer-cell:first-of-type,
mat-header-cell:first-of-type {
  padding-left: 24px;
}

.orderListing mat-cell {
  font-size: 0.875rem;
  font-weight: 400;
}

/* .div_claButton {} */

.div_claButton > div {
  height: 50%;
}

.btn_outer:first-child {
  border-bottom: #d1d1d1 1px solid;
}

.btn_outer {
  padding: 15px 5px 13px 39px;
  color: #12afec;
  background: url(assets/img/noun_View_772431.png) 10px center no-repeat #fff;
  display: block;
  margin-right: 2px;
  position: relative;
  /* z-index: 94; */
  font-size: 0.875rem;
  cursor: pointer;
}

.btn_outer i {
  vertical-align: middle;
}

.fileUpload.btn {
  /* white-space: nowrap; */
  height: 50px;
  width: 120px;
  align-items: center;
  display: inline-flex;
}

.div_claButton .btn_outer:hover {
  color: #fff;
  background: url(assets/img/noun_View_772431h.png) 10px center no-repeat
    #12afec;
}

.classbillSection {
  font-size: 1rem;
}

.colorDate {
  color: #d5d5d5;
  font-size: 14px;
}

.mat-expansion-panel-body {
  overflow: hidden;
}

.reViewSection .mat-progress-bar .mat-progress-bar-fill::after {
  background-color: #666 !important;
}

/* #TopB-T {
    background: #f2f2f2;
} */
.TopB-T {
  background-color: #f2f2f2 !important;
  min-height: 112px;
}

.inne_area_list_l {
  border-right: 2px dashed #adadad;
  min-height: 100px;
  padding: 1.25rem;
  overflow-x: auto;
  padding-right: 5px;
}

.inne_area_list_r {
  /* border-right: 2px dashed #ADADAD; */
  /* min-height: 100px; */
  padding-right: 23px;
  /* margin: 0px; */
}

.TopB-T .inne_area_list_r {
  padding-bottom: 10px;
  padding-top: 10px;
  margin-bottom: 7px;
  margin-top: 7px;
}

.tblSubHeading {
  border-top: none;
}

.tblSubHeading h4 {
  font-size: 1rem;
  font-weight: 800;
}

.tableMinWidth1000,
.tableScroll,
.largScrollTbl,
.tableMinWidth1024,
.tableMinWidth2600,
.tableMinWidth3000 {
  overflow: auto;
  width: 100%;
}

/* mat-table {
   min-width: 600px;
} */
mat-row {
  align-items: inherit !important;
}

.tableScroll mat-table {
  min-width: 800px;
}

.largScrollTbl mat-table {
  min-width: 1800px;
}

.tableMinWidth1024 mat-table {
  min-width: 800px;
}

.tableMinWidth2600 mat-table {
  min-width: 2600px;
}

.tableMinWidth3000 mat-table {
  min-width: 3000px;
}

mat-table {
  overflow: auto;
  /* max-height: 450px; */
  max-height: 100%;
}

.table_list {
  width: 100%;
  margin-bottom: 30px;
}

mat-table.table_list {
  background: none;
  border-collapse: separate;
  border-spacing: 0 10px;
  background-color: transparent;
  display: grid;
}

.inne_area_list mat-table.table_list {
  /* width: 800px; */
}

.inne_area_l_list mat-table.table_list {
  min-width: 100%;
}

.inne_area_list mat-table.table_list mat-row mat-cell {
  display: inline-block;
  text-align: initial;
}

mat-table.table_list mat-row mat-header-cell {
  border: none !important;
}

mat-table.table_list mat-row mat-cell {
  position: relative;
}

/* mat-cell {
  font-size: .6875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: left;
  color: #343434;
} */
.mat-cell,
.mat-header-cell,
.mat-footer-cell {
  /* color: #343434;
  font-size: .6875rem; */
  line-height: 1rem;
  font-weight: 400;
  overflow: visible;
  /* overflow-wrap: initial; */
}

.mat-footer-cell {
  font-weight: bold;
  color: #12afec;
}

.mat-header-cell {
  font-size: 0.75rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #12afec;
}

.content_area_list .table_list mat-table mat-row mat-cell:first-child {
  background: #fff;
}

.table_list mat-table mat-row.ng-star-inserted mat-cell:first-child:after {
  display: block !important;
}

mat-table.table_list mat-cell {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.01) 0px 3px 5px;
  margin-bottom: 10px;
  padding: 10px;
  border: none !important;
  min-height: 4rem;
}

mat-table.table_list mat-cell :active {
  /* background: #f5ec48; */
  background: #12afec !important;
  color: #fff !important;
}

mat-table.table_list mat-cell button:hover {
  /* background: #f5ec48; */
  background: #12afec !important;
  color: #fff !important;
}

mat-table.table_list mat-row.active mat-cell button {
  /* background: #f5ec48; */
  background: #12afec !important;
  color: #fff !important;
}

mat-table.table_list mat-header-row mat-header-cell {
  background: none;
  padding: 5px 10px 0;
}

mat-table.table_list mat-row mat-cell .mat-column-select:first-child {
  /* background: none; */
  width: 20px !important;
  padding: 10px;
}

mat-table.table_list mat-row mat-cell .mat-column-cbox {
  background: none;
  width: 20px !important;
  padding: 10px;
}

mat-table.table_list mat-row mat-cell.mat-column-cbox:after {
  display: none;
}

mat-table.table_list mat-row mat-cell:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  height: 2px;
  background: #ff9c39;
  width: 100%;
  left: 0;
  border-radius: 0px 0px 2px 2px;
}

mat-table.table_list mat-row mat-cell.border-dark_orange:after {
  background: #f37100;
}

mat-table.table_list mat-row mat-cell.border-green:after {
  background: #fff;
}

mat-table.table_list mat-row mat-cell.border-red:after {
  background: #f30006;
}

/* mat-table.table_list  mat-row.active mat-cell:first-child {
    border-right: 1px solid  #12afec !important;

} */
/* mat-table.table_list   mat-row.active mat-cell:nth-child(1)  {
    border-left: 1px solid  #12afec !important;
    border-top: 1px solid  #12afec !important;
    border-bottom: 1px solid  #12afec !important;
} */
/* mat-table.table_list   mat-row.active mat-cell:nth-child(2)  {

    border-top: 1px solid  #12afec !important;
    border-bottom: 1px solid  #12afec !important;
} */
mat-table.table_list mat-row.hovered mat-cell:nth-child(1) {
  border-left: 1px solid #12afec !important;
}

mat-table.table_list mat-row.hovered mat-cell:not(cbox) {
  border-top: 1px solid #12afec !important;
  border-bottom: 1px solid #12afec !important;
}

mat-table.table_list mat-row.hovered mat-cell:last-child {
  border-right: 1px solid #12afec !important;
}

mat-table.table_list mat-row.active mat-cell:nth-child(1) {
  border-left: 1px solid #12afec !important;
}

mat-table.table_list mat-row.active mat-cell:not(cbox) {
  border-top: 1px solid #12afec !important;
  border-bottom: 1px solid #12afec !important;
}

mat-table.table_list mat-row.active mat-cell:last-child {
  border-right: 1px solid #12afec !important;
}

/*
mat-table.table_list   mat-row.active mat-cell:nth-child(3)  {

    border-top: 1px solid  #12afec !important;
    border-bottom: 1px solid  #12afec !important;
}mat-table.table_list   mat-row.active mat-cell:nth-child(4)  {

    border-top: 1px solid  #12afec !important;
    border-bottom: 1px solid  #12afec !important;
}
mat-table.table_list  mat-row.active mat-cell:last-child{

    border-right: 1px solid  #12afec !important;
    border-bottom: 1px solid  #12afec !important;
    border-top: 1px solid  #12afec !important;

} */

mat-table.table_list mat-row mat-cell.border-orange:after {
  background: #ff9c39;
}

.lastTable mat-table.table_list mat-row mat-cell:after {
  display: none;
}

.td-task-button {
  max-width: 110px !important;
  /* color: #adadad; */
}

mat-cell.td-task-button span {
  text-align: center;
  justify-content: center;
  /* padding-right: 8px !important; */
  font-size: 0.625rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  /* color: #12afec; */
}

.button-task {
  max-width: 84px;
  white-space: inherit !important;
  text-transform: capitalize;
  padding: 0 8px !important;
  width: 100% !important;
  line-height: initial !important;
  min-height: 30px;
}

/* overflow-wrap: break-word; */
/* margin-top: 8px !important; */
/* margin-bottom: 8px !important; */
/* padding-right: 8px  !important; */
/* padding-left: 8px  !important; */

.mat-column-customer {
  max-width: 150px;
  min-width: 84px;
  overflow-wrap: break-word;
  white-space: inherit;
}

.mat-column-truck_type {
  /* min-width: fit-content; */
  max-width: 100px;
  white-space: inherit;
  overflow-wrap: break-word;
}

.mat-column-origin {
  /* min-width: fit-content; */
}

.details_list_item h2 {
  font-size: 1rem;
  padding-top: 25px;
  clear: both;
  padding-bottom: 10px;
}

.root_flow {
  padding: 10px 10px;
  background: #fff;
  position: relative;
  /* z-index: 2; */
  display: inline-block;
  text-align: center;
}

.root_flow img {
  margin-bottom: 10px;
}

.color_blue {
  color: #0099d3;
}

.color_green {
  color: #67db54;
}

.conatiner_flow {
  padding: 0px;
  position: relative;
}

.col_b_first {
  position: relative;
}

.col_b_fir {
  position: relative;
}

.col_b_second {
  position: relative;
}

.col_b_sec {
  position: relative;
}

.col_b_blue {
  position: relative;
}

.col_b_green {
  position: relative;
}

.col_b_grey {
  position: relative;
}

.conatiner_flow .col_b_first:after {
  content: "";
  background: url(/assets/img/dot_box.png) 0px 0px repeat-x;
  display: block;
  position: absolute;
  height: 4px;
  /* left: 37%;
    width: 100%; */
  left: 22%;
  width: 130%;
  top: 30%;
}

.conatiner_flow .col_b_first_thc:after {
  content: "";
  background: url(/assets/img/dot_box.png) 0px 0px repeat-x;
  display: block;
  position: absolute;
  height: 4px;
  left: 34%;
  width: 130%;
  top: 30%;
}

.conatiner_flow .col_b_second:after {
  content: "";
  /* background: url(/assets/img/dot_box_2.png) 0px 0px repeat-x; */
  display: block;
  position: absolute;
  height: 4px;
  left: 64%;
  width: 100%;
  top: 30%;
}

.conatiner_flow .col_first:after {
  content: "";
  background: url(/assets/img/dot_box.png) 0px 0px repeat-x;
  display: block;
  position: absolute;
  height: 4px;
  left: 35%;
  width: 100%;
  top: 30%;
}

.conatiner_flow .col_second:after {
  content: "";
  /* background: url(/assets/img/dot_box_2.png) 0px 0px repeat-x; */
  display: block;
  position: absolute;
  height: 4px;
  /* left: 33%; */
  left: 55%;
  /* width: 100%; */
  width: 75%;
  top: 30%;
}

.conatiner_flow .col_third:after {
  content: "";
  /* background: url(/assets/img/dot_box_2.png) 0px 0px repeat-x; */
  display: block;
  position: absolute;
  height: 4px;
  left: 70%;
  width: 75%;
  top: 30%;
}

.conatiner_flow .col_b_blue:after {
  /* content: ""; */
  background: url(/assets/img/dot_box.png) 0px 0px repeat-x;
  /* display: block;
  position: absolute;
  height: 4px;
  left: 28%;
  width: 100%;
  top: 30%; */
}

.conatiner_flow .col_b_grey:after {
  /* content: ""; */
  background: url(/assets/img/dot_box_2.png) 0px 0px repeat-x;
  /* display: block;
  position: absolute;
  height: 4px;
  left: 65%;
  width: 100%;
  top: 30%; */
}

.conatiner_flow .col_b_green:after {
  /* content: ""; */
  background: url(/assets/img/dot_box_3.png) 0px 0px repeat-x;
  /* display: block;
    position: absolute;
    height: 4px;
    left: 65%;
    width: 100%;
    top: 30%; */
}

.image_1_b {
  background: url(/assets/img/broker.png) 0px 0px no-repeat;
  height: 35px;
  width: 25px;
  margin-right: 5px;
  display: inline-block;
}

.image_2_u_b {
  background: url(/assets/img/user-g.png) 0px 0px no-repeat;
  height: 35px;
  width: 36px;
  margin-right: 5px;
  display: inline-block;
}

.labelBox_e3 {
  padding-bottom: 5px;
  font-weight: 700;
  font-size: 0.75rem;
  white-space: nowrap;
  overflow: visible;
  opacity: 0.8;
}

.details_list_item .fieldset {
  border-radius: 10px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.08);
  padding: 15px;
  background: #fff;
  /* margin: 15px 0 0; */
}

.fieldset .conatiner_flow .col {
  margin-bottom: 1rem !important;
  padding-left: 15px !important;
}

.fieldset .conatiner_flow .col-3 {
  margin-bottom: 1rem !important;
  padding-left: 15px !important;
}

.fileUpload {
  position: relative;
  /* overflow: hidden; */
  margin: 0;
  padding-bottom: 10px;
}

.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 1.25rem;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.dark_gary {
  background: #636363;
  border-radius: 21px;
  font-size: 0.6875rem;
  margin: 5px 0 30px;
  text-transform: capitalize;
  min-height: 2rem;
}

.details_list_item .dark_gary {
  margin-bottom: initial;
}

.fileUpload.dark_gary span {
  margin-top: 6px;
  padding-right: 5px;
  padding-left: 5px;
}

.col.fileUpload,
.col-3.fileUpload,
.col-4.fileUpload,
.col-6.fileUpload,
.col-12.fileUpload {
  display: inline-flex;
}

.dark_gary img {
  margin-right: 3px;
}

btn-light-upload {
  border-radius: 21px;
  font-size: 0.75rem;
  margin: 5px 0 30px;
}

.fields_t_b {
  font-size: 1rem;
  margin-bottom: 0;
}

/* .mat-fab.mat-primary,
.mat-flat-button.mat-primary,
.mat-mini-fab.mat-primary,
.mat-raised-button.mat-primary {

    background: #ffffff;
    color: #12afec !important;
    border: 1px solid #12afec;
    box-shadow: none;
    border-radius: 16px;
    text-transform: uppercase;
    min-width: 88px !important;

}
.mat-fab.mat-primary:hover,
.mat-flat-button:hover.mat-primary:hover,
.mat-mini-fab.mat-primary:hover,
.mat-raised-button.mat-primary:hover {

    background: #12afec;
    color:  #ffffff !important;
    border: 1px solid #12afec;
    box-shadow: none;
    border-radius: 16px;
    text-transform: uppercase;

} */
/* button{
    text-transform: uppercase;
} */
/* .details_list_item .mat-raised-button, */
.ierp .mat-raised-button {
  background: #ffffff;
  color: #12afec;
  border: 1px solid #12afec;
  box-shadow: none;
  border-radius: 16px;
  min-width: 88px !important;
  text-transform: capitalize;
}
.ierp .modal-header {
  padding: 0px;
}

.details_list_item .mat-raised-button {
  border-radius: 5px;
}

.details_list_item .mat-raised-button.active,
.details_list_item .mat-raised-button.hover,
.details_list_item .mat-raised-button.mat-primary,
.ierp .mat-raised-button.active,
.ierp .mat-raised-button.hover,
.ierp .mat-raised-button.mat-primary {
  background: #12afec;
  color: #ffffff !important;
  border: 1px solid #12afec;
  box-shadow: none;
  /* border-radius: 16px; */
  min-width: 88px !important;
}

form .details_list_item .mat-raised-button.mat-primary {
  border-radius: 5px !important;
  font-size: 1rem;
}

.details_list_item form .mat-raised-button.mat-primary {
  border-radius: 5px !important;
  font-size: 1rem;
}

.tranf_t {
  background: transparent;
  border: none;
  cursor: pointer;
}

.dividerB {
  padding-right: 10px;
  margin-right: 10px;
  border-right: #ccc 1px solid;
}

.tranf_t span {
  display: inline-block;
  vertical-align: middle;
  padding-right: 5px;
}

.gwheadline {
  color: #0099d3;
  font-size: 1rem;
  padding: 10px 0;
  /* margin-left: 7px; */
}

.mat-tab-body .gwheadline {
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.classautoB .mat-form-field-infix {
  width: auto;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: #adadad;
}

.mat-form-field-label {
  position: initial !important;
}

.mat-select-value {
  color: #343434;
}

/* log select options */
.long-select .mat-select-panel mat-option.mat-option {
  height: unset;
}

.long-select .mat-option-text.mat-option-text {
  white-space: normal;
}

.long-select .mat-select-value-text {
  height: unset;
  white-space: normal;
  font-weight: bold;
}

.done_task {
  height: 100%;
  display: inline-block;
  text-align: center;
  width: 100%;
  align-content: center;
}

.d_title_text {
  color: #0099d3;
}

.done_task img {
  height: 72px;
  margin-bottom: 10px;
  margin-top: 25%;
}

/* ::ng-deep .mat-progress-bar-fill::after {
    background-color: #1E457C;
    height: 6px;
} */

::ng-deep .mat-progress-bar-buffer {
  background: #cef1ff;
}

.mat-progress-bar-buffer {
  background: #cef1ff;
}

::ng-deep .mat-progress-bar {
  border-radius: 2px;
}

.mat-progress-bar {
  height: 7px !important;
  border-radius: 6px;
}

h1 {
  font-size: 24px;
}

#TopB-T h1 {
  color: #12afec;
}

/* .inne_area_list  mat-table.table_list  mat-row  mat-cell, mat-header-row mat-header-cell {
  padding-right: 10px;
  padding-top: 10px;
  align-items: baseline;
  display: inline-block;
} */

.Ellipse-9 {
  width: 6px;
  height: 6px;
  background-color: #12afec;
}

.TE-Request-Advance-1 {
  width: 1440px;
  height: 900px;
  background-color: #f9f9f9;
}

.Oval {
  width: 120px;
  height: 120px;
  border: solid 3px #12afec;
}

.Done {
  width: 57px;
  height: 24px;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #12afec;
}

.whiteFormShodow {
  font-size: 0.75 rem;
}

form .row {
  margin: 0px;
  /* min-height: 64px; */
}

form .col {
  margin-top: 8px;
  /* padding: .5rem; */
  /* padding-bottom: 8px; */
}

form [class*="col"] {
  margin-top: 8px;
  /* padding: 0 .5rem 0 0; */
  /* display: inline-table; */
}

form .mat-form-field {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.color-grey {
  color: #adadad;
}
.color-black {
  color: #000000;
}
.mat-icon {
  height: 22px !important;
  color: #12afec;
  font-size: 1.75rem;
}

.topbar-more-vert .mat-icon-button {
  /* font-size: 2.5rem;
  line-height: 40px !important;
  height:  40px !important;
  float: right; */
  float: right;
  right: 8px;
}

.topbar-more-vert .mat-icon-button .mat-icon {
  font-size: 1.75rem;
  /* line-height: 2.2125rem !important; */
  height: 40px !important;
  /* float: right; */
}

.mat-mini-fab .mat-button-wrapper {
  display: inline !important;
}

.mat-form-field-label-wrapper {
  /* top: -5px !important; */
  overflow: visible !important;
  margin-top: 5px;
}

.mat-input-element {
  color: #343434;
}

.top-section {
  font-size: 0.75rem;
}

/* .notificationSection .icon_bell .iconBell, .userIcon {
  font-size: 36px !important;
  color: #12afec !important;
  margin-right: 15px ;
} */
.logo {
  /* background: #fff; */
  /* padding: 8px; */
  /* width: 180px; */
  border-radius: 4px;
  margin: 0 17px;
  margin-top: 7px;
  /* margin-left: 30px; */
  margin-left: 20px;
  /* margin-top: 5px; */
  float: left;
}

@keyframes heartbeat {
  0% {
    transform: scale(0.75);
  }

  20% {
    transform: scale(1);
  }

  40% {
    transform: scale(0.75);
  }

  60% {
    transform: scale(1);
  }

  80% {
    transform: scale(0.75);
  }

  100% {
    transform: scale(0.75);
  }
}

.bounce-text {
  animation: heartbeat 1s infinite;
}

.color-progress-grey {
  color: #c8c8c8 !important;
}

.noun-correct-green {
  padding: 4px;
  background: url(assets/img/noun_correct_green.png) 0px 0px no-repeat;
  margin: 4px;
  vertical-align: -webkit-baseline-middle;
}

.noun-correct-grey {
  padding: 4px;
  margin: 4px;
  vertical-align: -webkit-baseline-middle;
  background: url(assets/img/noun_correct_grey.png) 0px 0px no-repeat;
}

.text-red {
  color: #1dca02;
}

.text-amber {
  color: #ffc100;
}

.text-green {
  color: #1dca02;
}

epod-status-green {
  color: #1dca02;
}

/* epod-status-grey{
  color: #343434;
} */
epod-status-amber {
  color: #ffc100;
}

.pod-days-rec-amber {
  border-radius: 7px;
  background-color: #ffc100;
  color: #ffffff;
  font-size: 8px;
  padding: 3px;
  margin: 3px;
}

.pod-days-color-amber {
  border-radius: 7px;
  background-color: #ffc100;
  color: #ffffff;
  font-size: 8px;
}

.pod-days-rec-red {
  border-radius: 7px;
  background-color: #f30006;
  color: #ffffff;
  font-size: 8px;
  padding: 3px;
  margin: 3px;
}

.pod-days-color-red {
  background-color: #f30006;
}

.pod-days-color-green {
  background-color: #1dca02;
}

.pod-days-rec-green {
  border-radius: 7px;
  background-color: #1dca02;
  color: #ffffff;
  font-size: 8px;
  padding: 3px;
  margin: 3px;
}

.label_sli {
  max-width: 80px;
  margin-top: 8px;
}

/* .show{
  opacity: 1 !important;
  }
.step{
  opacity: 0;
  transition: .5s ease-in-out all;

} */
.update_lr_buttons_group .mat-button-toggle {
  width: 160px;
  height: 32px;
  border-radius: 24px;
  background-color: #f1f1f1;
  flex-wrap: wrap;
}

.update_lr_buttons_group .mat-button-toggle-active,
.update_lr_buttons_group .mat-button-toggle-checked {
  background-color: #12afec;
  color: #ffffff !important;
}

@media screen and (max-width: 500px) {
  .update_lr_buttons_group .mat-button-toggle {
    width: 100px;
  }
}

.mat-datepicker-toggle-default-icon {
  color: #12afec;
  width: 1rem !important;
  height: 1rem;
}

.mat-datepicker-toggle {
  /* position: absolute; */
  top: -20px;
  right: 2px;
}

.create-order {
  width: 155px;
  height: 37px;
  border-radius: 95.5px;
  background-color: #12afec;
}

.details_list_item .mat-button-toggle-label-content span {
  white-space: break-spaces;
  line-height: inherit;
}
.details_list_item.nowrap .mat-button-toggle-label-content span {
  white-space: nowrap;
  line-height: inherit;
}
.cdk-overlay-container
  ::ng-deep
  .cdk-overlay-connected-position-bounding-box
  ::ng-deep
  .cdk-overlay-pane {
  right: 0;
}

.blue path {
  fill: blue !important;
}

.Done_style {
  justify-content: center;
  display: flex;
  margin-top: 20%;
}

.mat-badge-medium .mat-badge-content {
  min-width: 22px;
  width: initial;
}

.mat-column-select {
  max-width: 40px;
}

/* .mat-checkbox-inner-container {
  height: .6875rem !important;
  width: .6875rem !important;
} */
.mat-checkbox-inner-container {
  height: 1rem !important;
  width: 1rem !important;
}

.mat-flat-button.mat-warn,
.mat-raised-button.mat-warn,
.mat-fab.mat-warn,
.mat-mini-fab.mat-warn {
  /* color: #ADADAD; */
  border: 1px solid #adadad;
  box-shadow: none;
  /* background-color: #fff !important; */
}

.col {
  /* display: flex; */
  display: block;
}

.text-right {
  text-align: right !important;
}

/* .no-mobile{
    display: none;
} */
.s-mobile {
  margin-left: 10px;
}

.mat-button-toggle-group-appearance-standard
  .mat-button-toggle
  + .mat-button-toggle {
  border-left: none;
}

.material_close span.mat-button-focus-overlay {
  opacity: 1;
  background: none;
}

.outlinemat_btn .AssignTruckToggle.radius20 .mat-button-toggle {
  border: 1px solid #12afec;
  min-width: 136px;
  text-align: center;
}

.outlinemat_btn .AssignTruckToggle.radius20 .mat-button-toggle .AssignTruck {
  text-align: center;
}

.pod-table > table > thead > tr {
  display: flex;
  justify-content: space-around;
}

.pod-table > table > tbody > tr {
  display: flex;
  justify-content: space-around;
}

.pl-0 {
  padding-left: 0px;
}

.notificationSection-plus {
  padding-top: 8px;
}

@media screen and (max-width: 767px) {
  .notificationSection {
    float: left;
    position: relative;
    /* margin-top: 2px; */
    margin-right: 5px;
  }

  .notificationSection-plus {
    padding-top: 4px;
    margin-right: 0px;
  }

  .row > .col-md-12 {
    padding: 0px 15px;
  }
}

.mat-menu-panel {
  min-width: auto !important;
}

.details_list_item h3.gwheadline {
  font-size: 0.875rem;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  width: 0.9375rem !important;
  height: 0.9375rem;
  padding: 0;
  margin: 0;
  filter: invert(56%) sepia(31%) saturate(4706%) hue-rotate(164deg)
    brightness(103%) contrast(86%);
}

.btn-attach {
  display: flex !important;
}

@media screen and (max-width: 767px) {
  .cancelBtn {
    font-size: 12px !important;
    line-height: 25px !important;
    padding: 0px 1rem !important;
  }

  .submitBtn {
    font-size: 12px !important;
    line-height: 25px !important;
    padding: 0px 5px !important;
  }
}

.cancelBtn {
  background: #fff;
  color: #adadad !important;
  border: 1px solid #adadad !important;
  box-shadow: none !important;
  border-radius: 5px !important;
  min-width: 88px !important;
  margin-right: 1rem !important;
}

.submitBtn {
  background: #12afec !important;
  color: #ffffff !important;
  border: 1px solid #12afec !important;
  box-shadow: none !important;
  border-radius: 5px !important;
  min-width: 88px !important;
}

.mat-option {
  line-height: 16px;
  height: 40px;
}

.ti-pencil-alt {
  color: #12afec !important;
}

[mat-row].remove-background {
  background: none;
}

.img-zoom-container {
  position: relative !important;
  width: 100%;
  height: 180px;
}

.img-zoom-container .img-zoom-lens {
  width: 135px;
  height: 135px;
  left: 113px;
  top: 0px;
  position: absolute;
  visibility: hidden;
}

/* #myimage{
  width: 100%;
    height: 250px;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    margin: 0 auto;
    border: 1px solid #dcdcdc;
}

.img-zoom-result{
  width: 320px;
  height: 350px;
  background-repeat: no-repeat;
  z-index: 2;
  position: absolute;
  left: 0%;
} */

.mat-card-image {
  width: calc(100% + 32px);
  margin: 0 !important;
}

.mat-step-header .mat-step-icon {
  background-color: #12afec !important;
  color: #fff !important;
}

.mat-step-icon .mat-icon {
  font-size: 16px;
  height: 16px;
  width: 16px;
  color: #fff !important;
  margin-top: 2px !important;
}

/* AJAY CSS Start */
/* header styling start*/
.lefbar_arrow {
  float: right;
  margin-top: 7px;
}

/* .newlanding{
  padding: 0px !important;
} */

@media only screen and (max-width: 767px) {
  .rightUserSec .mat-badge-medium .mat-badge-content {
    height: 18px !important;
    right: -2px !important;
    top: -9px !important;
    min-width: 18px !important;
    width: unset !important;
  }

  .leftMenu {
    width: 200px;
    padding-top: 70px;
  }

  ul.navGroups {
    margin-top: 10px;
  }

  .navGroups > li > a {
    padding: 5px 10px;
    font-size: 12px !important;
  }

  .navGroups li .navGroupsNested li {
    padding: 8px 0px;
  }

  .navGroups li .navGroupsNested li a {
    padding: 0px;
  }

  .navGroups li .navGroupsNested li .nestPadding {
    margin-left: 0px !important;
    font-size: 11px;
  }

  .navGroups li a img {
    width: 24px;
  }

  .bottomImg {
    width: 100%;
  }

  .lefbar_arrow {
    font-size: 18px;
    margin-top: 11px;
  }
}

/* header styling end */

.newlanding.mat-button-toggle-appearance-standard
  .mat-button-toggle-label-content {
  padding: 0px !important;
}

.new_lr .mat-button-toggle {
  background: #f1f1f1;
  color: #adadad;
}

.newTruktoggle .mat-button-toggle {
  background: #f1f1f1;
  padding: 8px 10px !important;
  margin-top: 12px !important;
}

.newTruktoggle .AssignTruck {
  font-size: 12px;
}

/* table styling start */

.lr_exec_table thead tr.mat-header-row {
  height: 40px;
  background: #f3fcff;
}

.lr_exec_table th.mat-header-cell,
.lr_exec_table td.mat-cell,
.lr_exec_table td.mat-footer-cell {
  font-size: 12px;
  font-weight: normal;
  padding: 8px 3px;
}

.lr_exec_table th.mat-header-cell {
  color: #12afec;
  font-weight: bold;
  border-bottom: 0px;
  width: 25%;
  max-width: 25%;
  position: relative;
  top: 10px;
}

.lr_exec_table.lr_exec_table td.mat-cell {
  background-color: #fff;
}

/* table styling end */
.back_navigation {
  margin-left: -6px;
}

.back_navigation .mat-icon {
  height: unset !important;
}

.back_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mat-icon_btn {
  color: #12afec;
  align-items: center;
  display: flex;
  border: none;
  background: none;
  padding: 0px;
}

.mat-button-toggle-group.rounded__btn mat-button-toggle {
  padding: 11px 5px !important;
  text-align: center !important;
  margin: 0px !important;
  border-radius: 20px;
}

.mat-button-toggle-group.rounded__btn mat-button-toggle .AssignTruck {
  text-align: center;
}

.mat-radio-outer-circle {
  border-width: 1px !important;
}

.custum_radio .mat-radio-label-content {
  padding-left: 12px;
}

.custum_radio label.mat-radio-label {
  margin-bottom: 12px;
}

.checkImg .mat-radio-container:after {
  position: absolute;
  background-image: url("../src/assets/img/icons/radiocheck2x.png");
  content: "";
  height: 100%;
  width: 100%;
  left: 0px;
  background-size: contain;
}

.Mark__Reached .mat-datepicker-toggle {
  position: unset;
}

.modal_status_btn .mat-button-toggle-checked {
  color: #343434 !important;
}

mat-button-toggle-group.rounded__btn {
  display: grid;
  grid-template-columns: repeat(3, 150px);
  text-align: center;
  grid-gap: 10px;
}

@media (max-width: 400px) {
  .newTruktoggle .AssignTruck {
    font-size: 11px;
  }

  .lr_exec_table th.mat-header-cell,
  .lr_exec_table td.mat-cell,
  .lr_exec_table td.mat-footer-cell {
    font-size: 11px;
    /* font-weight: normal; */
  }
}

@media (max-width: 360px) {
  .newTruktoggle .AssignTruck {
    font-size: 9px;
  }

  .lr_exec_table th.mat-header-cell,
  .lr_exec_table td.mat-cell,
  .lr_exec_table td.mat-footer-cell {
    font-size: 9px;
    /* font-weight: normal; */
  }
}

@media (max-width: 330px) {
  .newTruktoggle .AssignTruck {
    font-size: 8px;
  }

  .lr_exec_table th.mat-header-cell,
  .lr_exec_table td.mat-cell,
  .lr_exec_table td.mat-footer-cell {
    font-size: 8px;
    /* font-weight: normal; */
  }
}

@media (max-width: 767px) {
  .back_navigation {
    margin-left: -11px;
  }

  mat-button-toggle-group.rounded__btn {
    grid-template-columns: repeat(3, 1fr);
  }

  .custum_radio .mat-radio-inner-circle,
  .custum_radio .mat-radio-container,
  .custum_radio .mat-radio-outer-circle,
  .custum_radio
    .mat-radio-button.mat-accent.mat-radio-checked
    .mat-radio-outer-circle {
    height: 12px;
    width: 12px;
  }
}

@media only screen and (min-width: 768px) {
  .back_navigation .mat-icon {
    margin-top: -1px;
  }

  .lr_exec_table th.mat-header-cell,
  .lr_exec_table td.mat-cell,
  .lr_exec_table td.mat-footer-cell {
    font-size: 12px;
    padding: 14px 3px;
  }

  .custum_radio .mat-radio-label {
    font-size: 14px;
    color: #343434;
  }

  .back_header button {
    font-size: 14px;
  }
}

/* modal styling start*/
.mobile_modal {
  max-width: 100%;
  min-width: 100%;
  margin: 0px;
  padding-top: 59px;
  background: #f9f9f9;
}

.mobile_modal .modal-content {
  border-radius: 0px;
  border: 0px;
  background: #f9f9f9;
}

@media (max-width: 991px) {
  /* .header { z-index: 1100;}
  .leftToggleMenu {z-index: 1101 !important;}
  .popup_header .header{
    background-color: #f9f9f9;
  } */
  /* bs-modal-backdrop.modal-backdrop.fade.in.show {
    opacity: 0;
} */
  /* Hide scrollbar for Chrome, Safari and Opera */
  modal-container.modal.fade.show::-webkit-scrollbar {
    display: none;
  }

  modal-container.modal.fade.show {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

/* modal styling end */

/* AJAY CSS End */

.mat-form-field-appearance-legacy .mat-hint {
  color: rgb(18, 175, 236);
}

.mat-form-field-appearance-outline .mat-form-field-outline .mat-hint {
  display: none;
}

.headerCell
  .mat-badge-medium.mat-badge-overlap.mat-badge-after
  .mat-badge-content {
  right: 0 !important;
  /* top: 5px!important; */
}

.headerCell .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: 0 !important;
}

@media only screen and (max-width: 767px) {
  .headerCell .mat-badge-medium .mat-badge-content {
    height: 16px !important;
    line-height: 15px;
    min-width: 15px !important;
    font-size: 8px;
    width: unset !important;
  }
}

.uploadimgwp {
  width: 252px;
  height: 92px;
  border: 1px dashed #d0d0d0;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.card-preview {
  /* width: 252px; */
  height: 92px;
  border: 1px dashed #d0d0d0;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.uploadfilecustomwp input[type="file"] {
  display: none;
}

.greenyellow {
  color: #379626 !important;
}
.orange {
  color: #f37100 !important;
}
.mat-icon-small {
  font-size: 1.25rem;
}
.ath-table .mat-header-cell {
  white-space: nowrap;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  letter-spacing: -0.04em;
}
.ath-table {
  width: 100% !important;
}
mat-table.ath-table mat-row mat-cell:after {
  background: none !important;
}
.ath-table mat-icon {
  background-color: none;
}
/* mat-table.ath-table mat-cell button:hover {
  background: none !important;
} */
.section-icons {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.table-priority-icon {
  border: 2px solid #12afec !important;
  border-radius: 4px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 35px !important;
  height: 35px !important;
}

.table-priority-icon mat-icon {
  font-size: 25px;
}
.table-done-icon {
  border: 2px solid #2dbb3b !important;
  border-radius: 4px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 35px !important;
  height: 35px !important;
}
.table-priority-icon:hover {
  background-color: #12afec !important;
}
.table-priority-icon:hover mat-icon {
  color: white !important;
}
#done-icon:hover {
  background-color: #2dbb3b !important;
}
#done-icon:hover mat-icon {
  color: white !important;
}
#reject-icon:hover {
  background-color: #ed223d !important;
}
#reject-icon:hover mat-icon {
  background-color: #ed223d !important;
  color: white !important;
}
.table-done-icon mat-icon {
  color: #2dbb3b !important;
  font-size: 25px;
}
.table-close-icon {
  border: 2px solid #ed223d !important;
  border-radius: 4px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 35px !important;
  height: 35px !important;
}

.table-close-icon mat-icon {
  color: #ed223d !important;
  font-size: 25px;
}
mat-table.ath-table mat-cell :active {
  /* background: #f5ec48; */
  background: none !important;
  /* color: #fff !important; */
}
.inne_area_list mat-table.ath-table mat-row mat-cell {
  font-family: "Gotham-Light", "Muli", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;
  display: flex;
  align-items: center;
}
.ath-table .mat-header-row {
  padding-bottom: 10px;
}

/* Dialog Css AthBthPriority */

.ath-bth-priority-dialog .title {
  font-family: "Gotham-Light", "Muli", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 23px;
  /* identical to box height */

  letter-spacing: -0.04em;

  color: #12afec;
}
.ath-bth-priority-dialog .submit-btn {
  background: #12afec;
  border-radius: 12px;
  outline: none;
  color: white;
  padding: 0.4rem 1.9rem;
}
.ath-bth-priority-dialog .sub-text {
  font-family: "Gotham-Light", "Muli", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  letter-spacing: -0.04em;

  color: rgba(51, 51, 51, 0.8);
}
.ath-bth-priority-dialog .sumbit-btn-disabled {
  color: white !important;
  opacity: 0.5;
}
.ath-bth-priority-dialog .input-class {
  padding: 0.5rem 1rem !important;
  background: #f0f0f0;
  border-radius: 12px;
  color: black;
  margin-top: 1rem;
}
.ath-bth-priority-dialog .input-class .mat-form-field-flex {
  background: #f0f0f0;
}
.ath-bth-priority-dialog .input-class .mat-form-field-underline {
  display: none;
}
.ath-bth-priority-dialog .input-class .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}
.ath-bth-priority-dialog
  .mat-form-field-appearance-fill
  .mat-select-arrow-wrapper {
  transform: translateY(-0%);
}
.ath-bth-priority-dialog .mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0 !important;
}
/* .mat-slid-red .mat-slide-toggle-thumb {
  background: #ff0000;
} */
.ath-text {
  font-family: "Gotham-Light";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 17px;
  letter-spacing: -0.04em;
  color: rgba(51, 51, 51, 0.6);
}
.color-red {
  color: #ff0000;
}
.dry-exim-mat-toggle {
  padding: 0.2rem 1rem;
}

.seaimport-form-main
  .mat-form-field-appearance-legacy
  .mat-form-field-underline {
  /* height: 0px !important; */
  border-bottom: 1px solid #cccccc !important;
  /* background: #fff !important; */
}
.custom-currency .mat-form-field-infix {
  padding: 0.9rem 0.9em !important;
  background-color: white !important;
  border-radius: 4px;
}
.custom-currency .mat-form-field-underline {
  display: none !important;
}
